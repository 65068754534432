import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import imgContest1 from 'assets/images/contest/1.png'
import imgContest2 from 'assets/images/contest/2.png'
import imgContest3 from 'assets/images/contest/3.png'
import imgAppStore from 'assets/images/app-store.svg'
import imgGooglePlay from 'assets/images/google-play.svg'
import imgContestApp1 from 'assets/images/contest/info1.png'
import imgContestApp2 from 'assets/images/contest/info2.png'
import imgContestApp3 from 'assets/images/contest/info3.png'
import imgTabletBg from 'assets/images/tablet-bg.png'

const CONTEST_DAY = new Date('Oct 26, 2020 0:00:00')

const Contest = () => {
  const { t, i18n } = useTranslation()
  const [time, setTime] = useState(undefined)
  const [, setContestEndTime] = useState(undefined)

  useEffect(() => {
    setInterval(function () {
      const oneDay = 24 * 60 * 60 * 1000
      const nowDate = new Date()
      const leftDate = new Date(CONTEST_DAY)

      var diffDays = Math.floor(Math.abs((nowDate - leftDate) / oneDay))

      var countDownDate = new Date(CONTEST_DAY)
      let now = new Date().getTime()

      let distance = countDownDate.getTime() - now

      if (distance < 0) {
        setTime('over')
        // const CONTEST_END_DATE = new Date('Dec 21, 2020 0:00:00')
        const CONTEST_END_DATE = new Date('Dec 21, 2020 0:00:00')
        diffDays = Math.floor(
          Math.abs((new Date() - CONTEST_END_DATE) / (24 * 60 * 60 * 1000))
        )
        countDownDate = new Date(CONTEST_END_DATE)
        distance = countDownDate.getTime() - now

        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        let seconds = Math.floor((distance % (1000 * 60)) / 1000)

        return setContestEndTime({ days: diffDays, hours, minutes, seconds })
      }

      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      let seconds = Math.floor((distance % (1000 * 60)) / 1000)

      return setTime({ days: diffDays, hours, minutes, seconds })
    }, 1000)
  }, [time])

  return (
    <section className="content contest pb-layout-sm">
      <div className="wrapper-header">
        <div className="container">
          <Link
            to="/"
            className="d-inline-block title text-white text-shadow wow fadeIn"
          >
            Edu Market
          </Link>
        </div>
      </div>
      <div className="container">
        <h1 className="text-center title py-layout-xs wow fadeIn">
          {t('contest.title')}
        </h1>
        

        {!time ? (
          ''
        ) : time === 'over' ? (
          <div className="wow fadeIn">
            <h2 className="title title--sm text-center text-danger pb-md">{ t('contest.end') }</h2>
            <div className="text-center pb-xl">
              <Link to="/" className="btn btn--green btn--outer-shadow">{ t('leaders_table') }</Link>
            </div>
          </div>
        ) : (
          <div className="wow fadeIn mb-md">
            {/* <h2 className="title title--sm text-center text-danger">{ t('contest.started') }</h2> */}
            <h2 className="title title--sm text-center">{t('contest.left')}</h2>
            {time && (
              <div className="contest__countdown pb-lg">
                <div className="contest__countdown-item">
                  {time.days} {t('days')}
                </div>
                <div className="contest__countdown-item">
                  {time.hours} {t('hours')}
                </div>
                <div className="contest__countdown-item">
                  {time.minutes} {t('minutes')}
                </div>
                <div className="contest__countdown-item">
                  {time.seconds} {t('seconds')}
                </div>
              </div>
            )}
            {/* <div className="text-center pb-xl">
								<Link to="/leaderboard" className="btn btn--green btn--outer-shadow">{ t('leaders_table') }</Link>
							</div> */}
          </div>
        )}

        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 wow fadeInUp">
            <h2 className="title title--sm pb-lg">{t('contest.info_1')}</h2>
            <div className="video">
              <img src={imgTabletBg} className="video__thumb" alt="video" />
              {i18n.language === 'ru' ? (
                <video
                  poster="/images/history/july-aug-2020.jpg"
                  className="video__item"
                  controls
                >
                  <source src="/video/contest_ru.mp4" type="video/mp4" />
                  Не получилось открыть видео.
                  <a href="/video/contest.mp4">Скачать</a>
                </video>
              ) : (
                <video
                  poster="/images/history/july-aug-2020.jpg"
                  className="video__item"
                  controls
                >
                  <source src="/video/contest.mp4" type="video/mp4" />
                  Не получилось открыть видео.
                  <a href="/video/contest.mp4">Скачать</a>
                </video>
              )}
            </div>
            <p className="text-bold lh">{t('contest.text_1')}</p>
            <p
              className="text-bold lh pb-layout-xs pt-sm"
              dangerouslySetInnerHTML={{ __html: t('contest.text_2') }}
            ></p>
          </div>

          <div className="col-12 wow fadeInUp mb-layout-xs">
            <div className="row justify-content-lg-start justify-content-md-center">
              <div className="col-md-8 offset-lg-2">
                <h2 className="title title--sm pb-layout-xs">
                  {t('contest.info_2')}
                </h2>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="contest__prize">
                  <div className="contest__prize-wrap">
                    <img src={imgContest1} alt="contest img" />
                  </div>
                  <p className="text-md pb-md">{t('contest.prize.info_1')}</p>
                  <p className="lh">{t('contest.prize.text_1')}</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="contest__prize">
                  <div className="contest__prize-wrap">
                    <img src={imgContest2} alt="contest img" />
                  </div>
                  <p className="text-md pb-md">{t('contest.prize.info_2')}</p>
                  <p className="lh">{t('contest.prize.text_2')}</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="contest__prize">
                  <div className="contest__prize-wrap">
                    <img src={imgContest3} alt="contest img" />
                  </div>
                  <p className="text-md pb-md">{t('contest.prize.info_3')}</p>
                  <p className="lh">{t('contest.prize.text_3')}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 wow fadeInUp">
            <h2 className="title title--sm pb-layout-xs">
              {t('contest.info_3')}
            </h2>
            <ul className="list list--check-blue list--align-start list--spaced">
              <li className="align-items-start mb-lg">
                <p>{t('contest.list_1')}</p>
                <div className="app-actions mt-sm flex-wrap">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.gamezale.edumarket"
                    rel="noopener noreferrer"
                    alt="google play"
                    className="mb-sm"
                    target="_blank"
                  >
                    <img src={imgGooglePlay} alt="GooglePlay" />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/edu-market/id1504756814?l=ru&ls=1"
                    rel="noopener noreferrer"
                    alt="google play"
                    className="mb-sm"
                    target="_blank"
                  >
                    <img src={imgAppStore} alt="AppStore" />
                  </a>
                  <a
                    href="https://edumarket.uz/files/edu_market.apk"
                    rel="noopener noreferrer"
                    className="btn btn--default-outline btn--md btn--thick-border mb-sm"
                    target="_blank"
                    download
                  >
                    {t('downloadFromSite')}
                    <span className="d-block">Tas-ix</span>
                  </a>
                </div>
              </li>
              <li>
                <p className="pb-lg">{t('contest.list_2')}</p>
                <img
                  src={imgContestApp1}
                  style={{ maxWidth: '100%', borderRadius: 15 }}
                  alt="Contest app"
                />
              </li>
              <li>
                <p className="pb-lg">{t('contest.list_3')}</p>
                <img
                  src={imgContestApp2}
                  style={{ maxWidth: '100%', borderRadius: 15 }}
                  alt="Contest app"
                />
              </li>
              <li>
                <p className="pb-lg">{t('contest.list_4')}</p>
                <img
                  src={imgContestApp3}
                  style={{ maxWidth: '100%', borderRadius: 15 }}
                  alt="Contest app"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contest
