import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch, Link } from 'react-router-dom'

import iconUz from 'assets/images/icon-uz.svg'
import iconRu from 'assets/images/icon-ru.svg'
import imgNews from 'assets/images/celebration.svg'
import imgGift from 'assets/images/gift-box.svg'
import imgHistory from 'assets/images/clock.svg'
import imgGallery from 'assets/images/photo.svg'
// import imgLeaderboard from 'assets/images/leaderboard.svg'

const TheHeader = ({ lang, onLangChange }) => {
	const { i18n, t } = useTranslation()
	const results = useRouteMatch({path: '/leaderboard'})
	const celebration = useRouteMatch({path: '/celebration'})
	if (results || celebration)
		return <div></div>

    const changeLanguage = lng => {
        i18n.changeLanguage(lng)
        onLangChange(lng)
	}
	
	return (
		<header className="header">
			<div className="container">
				<div className="header-wrap">
					<a href="https://cabinet.edumarket.uz/login"
						target="_blank" rel="noopener noreferrer"
						className="btn btn--green btn--outer-shadow header__btn wow fadeIn"
						dangerouslySetInnerHTML={{__html: t('cabinet')}}></a>
					<div className="header-wrap__inner">
						<Link to="/celebration" className="link header__contest header__item d-flex align-items-center wow fadeIn">
							<img src={imgNews} className="mr-sm" alt={t('celebration.title_short')}/>
							<span>{t('celebration.title_short')}</span>
						</Link>
						<Link to="/contest" className="link header__contest header__item d-flex align-items-center wow fadeIn">
							<img src={imgGift} className="mr-sm" alt={t('contest.title')}/>
							<span>{ t('contest.title') }</span>
						</Link>
						{/* <Link to="/leaderboard" className="link header__contest header__item d-flex align-items-center wow fadeIn">
							<img src={imgLeaderboard} className="mr-sm" alt={ t('leaderboard') }/>
							<span>{ t('leaderboard') }</span>
						</Link> */}
						<Link to="/history" className="link header__item d-flex align-items-center wow fadeIn">
							<img src={imgHistory} className="mr-sm" alt={t('history.title')}/>
							<span>{ t('history.title') }</span>
						</Link>
						<Link to="/gallery" className="link header__item d-flex align-items-center wow fadeIn">
							<img src={imgGallery} className="mr-sm" alt={t('gallery.title')}/>
							<span>{ t('gallery.title') }</span>
						</Link>
						{i18n.language === 'ru'
							? <button className="header__lang header__item link d-flex align-items-center wow fadeIn" onClick={() => changeLanguage('uz')}>
								<img src={iconUz} alt="country flag" />
								<span className="ml-sm text-white text-shadow">O’zbek tili</span>
							</button>
							: <button className="header__lang header__item link d-flex align-items-center wow fadeIn" onClick={() => changeLanguage('ru')}>
								<img src={iconRu} alt="country flag" />
								<span className="ml-sm text-white text-shadow">Русский язык</span>
							</button>
						}

					</div>
				</div>
			</div>
		</header>
	)
}

export default TheHeader
