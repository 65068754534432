export default {
  seedstars_contest: `"Game Zale" MChJ - "Seedstars Tashkent 2020" xalqaro startap-tanlovida faxrli 2-o'rin sohibasi`,
  main:
    "Gadjetni farzandlaringiz uchun nafaqat ko'ngilochar o'yinchoq sifatida, balki ta'lim vositasiga aylantiring",
  more: 'Batafsil',
  learnMore: "KO'PROQ BILISH",
  downloadApp: 'Ilovani yuklab olish',
  downloadFromSite: 'Saytdan yuklash',
  cabinet: '<span>Shaxsiy kabinet</span><span class="hidden">Kabinet</span>',
  loading: 'Yuklanmoqda',
  leaderboard: 'Liderlar',
  leaders_table: 'Peshqadamlar',
  days: 'k',
  hours: 's',
  minutes: 'd',
  seconds: 's',
  benefit: {
    title: 'Edu Market afzalliklari',
    info_1:
      "Mutaxassislarimiz tomonidan ishlab chiqilgan mashqlar, murakkablashib boruvchi masalalar tufayli erishilgan ko'nikmalar hajmini oshiradi",
    info_2:
      "Bola har bir belgilagan to'g'ri javobi va tirishqoqligi uchun mukofotlanib, shuningdek, har bir o'tilgan mashg'ulot evaziga o'zining virtual maktabini yaratish uchun zarur predmetlarga ega bo'ladi"
  },
  call: {
    title: "Onlayn O'rganish tizimi va fanlardan foydalanish imkoniyati",
    info:
      "Maktab muassasalarida ommaviy statistika ma'lumotlarini baholash, monitoring va tahlil qilish"
  },
  eco: {
    main_title: 'Edu Market xususiyatlari',
    title_1: 'Virtual maktab',
    info_1:
      "Turli xil kitoblar, jurnallar, o'quv qo'llanmalari, navigatsiya vositalari va toifalar bo'yicha izlash bilan jihozlanib tartibga solingan to'plam",
    title_2: "Onlayn O'rganish tizimi va fanlardan foydalanish imkoniyati",
    info_2:
      "Butun dunyo bo'ylab 24/7 turli xil fanlar bo'yicha o'quv materiallarining ulkan to'plami",
    title_3: 'Virtual kutubxona',
    info_3:
      "Pedagogik jarayon va talabalar/o'quvchilarni o'qitish gadjetlar orqali amalga oshiriladigan ta'lim muassasasi",
    title_4: "Bola o'zlashtirish faoliyatining onlayn statistikasi",
    info_4:
      "Pedagogik jarayon va talabalar/o'quvchilarni o'qitish gadjetlar orqali amalga oshiriladigan ta'lim muassasasi",
    title_5: 'Virtual shahar, virtual uy',
    info_5:
      "Texnik vositalar yordamida yaratilgan dunyo, inson his-tuyg'ulari orqali unga yetkaziladi: ko'rish, eshitish, teginish va boshqalar",
    title_6: 'Onlayn pointlar (ballar)',
    info_6:
      "Onlayn pointlar (ballar) – Tanga (ichki valyuta)ga almashtirilishi mumkin bo'lgan ballar hamda topshiriqlarni muvaffaqiyatli o'tganligi uchun turli bonuslarga ega bo'lish"
  },
  pupils: {
    title: "O'quvchilar nimalarga ega bo'lishadi",
    info_1: "Turli xil fanlar bo'yicha o'quv materiallaridan foydalanish",
    info_2: 'Mashqlarni muvaffaqiyatli bajarganligi uchun turli xil bonuslar',
    info_3: "Tangalar (ichki valyuta)ni almashtirish mumkin bo'lgan ballar",
    info_4:
      "Ijtimoiylashuvning yaxshilanishi (boshqa sinf va maktab o'quvchilari bilan muloqot)",
    info_5: 'Gadjetlar bilan yanada unumli foydalanish',
    info_6: "O'qishga bo'lgan motivatsiya"
  },
  goal: {
    title: 'Loyiha maqsadlari',
    info_1:
      "Bolalar uchun gadjetni nafaqat ko'ngilochar, balki to'laqonli o'rganish vositasiga aylantirish.",
    info_2:
      "Har bir yosh uchun bilim olishning tartibga solingan to'g'ri tizimini yaratish",
    info_3:
      "Ota-onalar hamda o'qituvchilar uchun bolaning o'zlashtirish faoliyatini monitoring qilishning shaffof tizimi.",
    info_4:
      "Bilimlarga ega bo'lish hamda o'rganish jarayonini yanada qulay va maroqli qilish"
  },
  steam: {
    title: "STEAM ta'lim",
    info:
      "STEAM avlodi - bu zamonaviy dunyoning eng ko'p talab qilinadigan avlodlaridan biridir. STEAM ta'limi nafaqat o'rganish uslubi, balki o'quv jarayoniga amaliy mashg'ulotlarni kiritish orqali fikrlash, materialni o'zlashtirish usullaridan biri hisoblanadi. Amaliy qobiliyatlarga e'tibor qaratgan holda, o'quvchilar iroda, ijodkorlik, moslashuvchanlikni rivojlantiradilar va boshqalar bilan hamkorlik qilishni o'rganadilar."
  },
  parents: {
    title: "Ota-onalar nimalarga ega bo'ladilar",
    info_1: "O'quvchilar bilan yanada yaqinroq faoliyat",
    info_2: "Bolaning manfaatlari va sevimli mashg'ulotlarini anglash",
    info_3: "O'qituvchilar bilan bevosita muloqot",
    info_4: "Real vaqtdagi o'zlashtirish ko'rsatkichlari statistikasi"
  },
  government: {
    title: "Davlat uchun qanday foyda bo'ladi",
    info_1: 'Turli sohalarda tayyorlangan kadrlar',
    info_2: 'Yoshlar manfaatlarini yanada chuqurroq anglash',
    info_3:
      "Yoshlarni rivojlantirishning yo'nalishli vektorini belgilash platformasi",
    info_4:
      'Kelgusida tuzatib borish uchun kadrlar resursidagi zaif nuqtalarni erta aniqlash'
  },
  footer: {
    contact_us: `Hamkorlik uchun, siz <a href="mailto:developer@gamezale.com" class="text-underline">
      info@gamezale.uz
    </a> elektron pochta orqali biz bilan bog'lanishingiz mumkin`,
    rights: 'Barcha huquqlar himoyalangan',
    policy: 'Konfidentsiallik siyosati',
    terms: 'Foydalanuvchi shartnomasi',
    devs: 'da Ishlab chiqarilgan'
  },
  results: {
    player_id: 'ID',
    points: 'Ball',
    spent_time: "O'yin vaqti"
  },
  partners: {
    title: 'Hamkorlarimiz',
    content: `
            <div class="wow fadeInUp pb-layout-xs" data-wow-delay="0.1s">
                <p>O'zbekiston Respublikasi Xalq ta'limi vazirligi, "Innovatsiya, texnologiya va strategiya markazi" DUK, AT "Aloqabank", A'lo finance kabi tashkilotlarning faol ishtiroki va professional qo'llab-quvvatlashi tufayli Edu Market interaktiv-virtual taʼlim platformasining muvaffaqiyatli ishga tushirilishi imkoni yaratildi.</p>
                <p>Mazkur hamkorlarimiz bizning faoliyatimizni olib borishda har tomonlama qo'llab-quvvatlaganliklari uchun o‘z minnatdorchiligimizni bildiramiz hamda kelgusidagi yanada ilgʻor loyihalarni birgalikda amalga oshirishni umid qilib qolamiz.</p>
                <p>O'zbekiston Respublikasi Xalq ta'limi vazirligi hamda "Innovatsiya, texnologiya va strategiya markazi" DUKga mazkur platformani sinovdan oʻtkazish, tadbiq etish, tahlil qilish va yakunlash bosqichlarida ko'rsatgan amaliy yordam uchun alohida minnatdorchilik bildiramiz.</p>
            </div>
        `,
    ITSM: `
            <div class="wow fadeInUp pb-layout-xs" data-wow-delay="0.2s">
                <h1 class="font-bold pb-md">Oʻzbekiston Respublikasi Xalq ta'limi vazirligi</h1>
                
                <p>Xalq ta'limi vazirligi umumiy o'rta taʼlim va maktabdan tashqari ta'lim sohasida yagona davlat siyosatini ishlab chiqib hamda uni amalga oshiruvchi O'zbekiston Respublikasi davlat boshqaruvining vakolatli organi hisoblanadi.</p>
                <p>Vazirlikning ustuvor vazifalaridan biri umumiy o‘rta va maktabdan tashqari ta’lim sohasida o‘quvchilarning ma’naviy-axloqiy, intellektual va jismoniy rivojlanishi darajasini yanada yuksaltirishga yo‘naltirilgan yagona davlat siyosatini ishlab chiqish va amalga oshirish hisoblanadi.</p>
                <p>Belgilangan vazifalarni sifatli amalga oshirish uchun vazirlik huzurida umumiy o'rta ta'lim va maktabdan tashqari ta'lim sohasini rivojlantirish strategiyasi ishlab chiqib, o'quv-tarbiya jarayoniga tadbiq etish uchun axborot texnologiyalarini optimallashtirish va samarali qo'llash bo'yicha kompleks chora-tadbirlar amalga oshirmoqda.</p>
            </div>
        `,
    MNO: `
            <div class="wow fadeInUp pb-layout-xs">
                <h1 class="font-bold pb-md">Xalq ta'limi vazirligi huzuridagi Innovatsiya, texnologiya va strategiya markazi</h1>
                
                <p>Markazning missiyasi bo'lib, Xalq ta'limi tizimini innovatsion rivojlantirish, raqamli texnologiyalar, veb-platformalar, dasturiy mahsulotlar va axborot tizimlaridan foydalangan holda zamonaviy innovatsion texnologiyalarni joriy etish, ta'lim tizimini yanada rivojlantirishni strategik tahlil qilish va rejalashtirishdan iborat.</p>
                <p>Hozirgi kunda Markazning muvaffaqiyatli loyihalaridan biri O'zbekiston Respublikasi Prezident maktablari, "Edu Market" interaktiv-virtual ta'lim platformasi, Xalq ta'limi sohasini rivojlantirish bo'yicha g'oyalar "idea.itsm.uz" veb-portali kabi qator loyihalarni amalga oshirib, shuningdek, xalq ta'limi tizimiga yangi tashabbuslarni amalga oshirish va joriy etish bo'yicha qator loyihalarni tadbiq etishga qaratilgan, shu jumladan aholi o'rtasida kitobxonlik madaniyatini oshirish va kitoblarni o'qish va tinglash imkoniyatini yaratish maqsadida "kitob.uz" bolalar elektron kutubxonasi veb-portali, "Edu Kids" ilmiy-ommabop animatsion video-to'plamlari kabi loyihalarni amalga oshirmoqda.</p>
            </div>
        `
  },
  gallery: {
    title: 'Galereya',
    item_1: 'Loyihaning rasmiy taqdimoti',
    item_2: "60-bolalar bog'chasida mahsulotni sinovdan o'tkazish",
    item_3: '64 va 94-maktablarda DEMO sinovlari',
    item_4: 'MVPni ishlab chiqish va amalga oshirishning boshlanishi',
    item_5: "Maktabgacha ta'lim vazirligida namoyish qilish"
  },
  contest: {
    title: 'Konkurs',
    info_1:
      'Edu Market O‘zbekiston Respublikasi Xalq ta’limi vazirligi bilan 12 yoshgacha bo‘lgan bolalar uchun onlayn olimpiada e’lon qiladi!',
    info_2: 'Ishtirokchilarni quyidagi sovg‘alar kutmoqda:',
    text_1: `Aziz do'stlar! Olimpiada 8 hafta davomida bo'lib o'tadi: 2020-yil 26-oktabrdan 2020-yil 21-dekabrga qadar. Har hafta yakuni bo'yicha peshqadamlik jadvalida o'rin egallagan 10 nafar g'oliblar taqdirlanadi. O'zbekiston Respublikasi hududida yashovchi 12 yoshgacha bo'lgan har qanday bola onlayn olimpiadada ishtirok etishi mumkin.`,
    text_2: `<span class="text-underline" style="font-size: 18px">Eslatmalar:</span><br/>
                - Barcha Edu Market mashqlarini ("Arifmetika" bo'limidan tashqari) 3 martagacha takroran o'ynash mumkin - keyin ballar hisobga olinmaydi;<br/>
                - "Arifmetika" bo'limida har bir urinish uchun maksimal 10 ball bilan faqat 3 urinishga ruxsat beriladi;<br/>
                - Bola faqatgina bir marta g'olib bo'lish huquqiga ega;<br/>
                - Avvalgi musobaqa g'oliblari va sovrindorlari mazkur olimpiadada ishtirok eta olmaydilar;<br/>
                - Ota-onalar farzandlari o'rniga mashqlarni bajarishda yordam bermasliklarini TAVSIYA qilamiz!`,
    prize: {
      info_1: '1 o‘rin',
      text_1: 'Planshet, futbolka, kepka, vaucher, sertifikat',
      info_2: '2 и 3 o‘rin',
      text_2: 'Futbolka, kepka, vaucher, sertifikat, ruchka, daftar',
      info_3: 'с 4 по 10 o‘rin',
      text_3: 'Futbolka, qalpoq, ruchka, daftar'
    },
    info_3: 'Onlayn olimpiadada ishtirok etish uchun nima qilish kerak?',
    list_1: 'Edu Market ilovasini smartfon yoki planshetingizga o‘rnating',
    list_2:
      'Edu Market ilovasida ro‘yxatdan o‘ting (familiyangiz, ismingiz va telefon raqamingizni to‘g‘ri ko‘rsatganingizga ishonch hosil qiling, g‘alaba qozongan taqdiringizda biz Siz bilan bog‘lanamiz)',
    list_3:
      'Edu Market ilovasining har qanday bo‘limida o‘ynang va ball to‘plang',
    list_4:
      'To‘plangan ballarni ilova yoki https://edumarket.uz/ sayti orqali "Liderlar"ni bosib tekshiring',
    left: 'musobaqa boshlanishi vaqti',
    started: 'Musobaqa allaqachon boshlangan!',
    will_finish: 'musobaqaning oxirigacha',
    first_stage:
      'Musobaqaning birinchi bosqichi o‘tdi!<br /> Bizning g‘oliblarimiz:',
    second_stage:
      'Musobaqaning ikkinchi bosqichi o‘tdi!<br /> Bizning g‘oliblarimiz:',
    sud_info:
      'Hurmatli Onlayn Olimpiada ishtirokchilari, afsuski, biz o‘z olimpiadamizni 13.07.2020 dan 20/07/2020 yilgacha to‘xtatib qo‘yishga majburmiz. Ushbu davrda to‘plangan ballar hisobga olinmaydi.',
    sud_info_1:
      'Hurmatli Onlayn Olimpiada ishtirokchilari, afsuski, biz o‘z olimpiadamizni 13.07.2020 dan 20/07/2020 yilgacha to‘xtatib qo‘yishga majburmiz. Ushbu davrda to‘plangan ballar hisobga olinmaydi. Olimpiada to‘xtatib qo‘yilishiga sabab, nomaqbul tarzda etakchi o‘rinlarni egallashga harakat qilgan odamlar bo‘lgan. Biz halol olimpiada o‘tkazmoqchimiz, unda ta‘lim va o‘qishga qiziqqan bolalar o‘z sa‘y-harakatlari uchun mukofotlarga sazovor bo‘ladilar.',
    sud_info_2:
      'Onlayn olimpiadaning 2-bosqichi 2020 yil 20-iyulda boshlanadi va barqaror rejimda davom etadi, shuning uchun siz bo‘lajak musobaqaga tayyorgarlik ko‘rishga vaqtingiz bor.',
    sud_info_3:
      'Avgust oyida barcha sovrinlar koronavirus infektsiyasi bo‘yicha yuzaga kelgan vaziyat tufayli topshiriladi.',
    soon_stats:
      'Olimpiada allaqachon boshlangan, yaqin orada statistika ma‘lum qilinadi',
    stage_completed:
      'Musobaqaning yana bir bosqichi o‘tdi!<br /> Bizning g‘oliblarimiz:',
    old_stage_completed:
      'Oldingi musobaqaning natijalari<br />Bizning g‘oliblarimiz:',
    week_1: 'Hafta 1',
    week_2: 'Hafta 2',
    week_3: 'Hafta 3',
    week_4: 'Hafta 4',
    week_5: 'Hafta 5',
    week_6: 'Hafta 6',
    week_7: 'Hafta 7',
    week_8: 'Hafta 8',
    end: 'Musobaqa tugadi!'
  },
  news: {
    title: 'Yangiliklar'
  },
  celebration: {
    title: 'Biz 330 mingdan ziyod foydalanuvchilarga egamiz!',
    title_short: 'Biz 330 000!',
    title_text: 'foydalanuvchi!',
    text: `
            <p style="font-size: 18px; line-height: 28px">
            Aziz do'stlar, jamoamiz nomidan loyihamizda ishtirok etayotganingiz uchun minnatdorchilik bildiramiz!<br />
            Ha, aynan sizlar bizning Edu&nbsp;Market platformamizni har safar yaxshilashga va eng muhimi, bolalarga yangi bilimlar berishga yordam bermoqdasiz!<br /></p>
            <p style="font-size: 20px" class="text-danger">Biz bilan birga bo'lganingiz uchun tashakkur!</p>
        `
  },
  history: {
    title: 'Tarix',
    sep_2018: `
            <div>
                <h3>
                    <p>Sentyabr</p>
                    <p>2018</p>
                </h3>
            </div>
            <div>
                <h3>Rivojlanishning boshlanishi</h3>
                <p>Bizning jamoamiz muallifning qarashlari, talqinlari va asosiy g'oyalari to'g'risida qaror qabul qildilar. Platformani rivojlantirish bo'yicha asosiy printsiplar, strategiya va harakatlar shakllantirildi.</p>
                <img src="/images/history/sep-2018-1.jpg" alt="history"/>
                <img src="/images/history/sep-2018-2.jpg" alt="history"/>
            </div>
        `,
    feb_2019: `
            <div>
                <h3>
                    <p>Fevral</p>
                    <p>2019</p>
                </h3>
            </div>
            <div>
                <h3>MVP versiyasi</h3>
                <p>Kontseptual qarashlarga asoslanib, biz o'z funktsiyalari bilan tinglovchilarning asosiy ehtiyojlarini qondiradigan platformaning asosiy funktsional imkoniyatlarini amalga oshirdik.</p>
                <img src="/images/history/feb-2019.JPG" alt="history"/>
            </div>
        `,
    is_2019_1: `
            <div>
                <h3>
                    <p>2019</p>
                </h3>
            </div>
            <div>
                <h3>1000+ o'quv o'yinlari tomonidan ishlab chiqilgan</h3>
                <p>Biz jamoaviy ruh va ishtiyoq bilan ishlaydigan, ijodiy ishlangan xodim sifatida qisqa vaqt ichida yosh foydalanuvchilarimiz uchun turli mavzular va yoshlarga oid 1000 dan ortiq mini-o'yinlarni o'tkazdik.</p>
            </div>
        `,
    is_2019_2: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Statistik tizimlar ishlab chiqilgan</h3>
                <p>Statistika har qanday o'quv jarayoni muvaffaqiyatining muhim tarkibiy qismidir. Platformada statistika ko'rsatkichlarni kuzatish, kuchli va zaif tomonlarini tahlil qilish uchun ham zarur narsadir. Shunday qilib, biz ma'lumotlar statistikasini to'plash va tahlil qilish uchun moslashuvchan tizimni ishlab chiqdik. Shuningdek, foydalanuvchilar o'zlarining muvaffaqiyatlari va kamchiliklarini kuzatib borishlari va shu bilan o'zlarining bilimlarini oshirishlari mumkin.</p>
                <img src="/images/history/is-2019-2.png" alt="history"/>
            </div>
        `,
    is_2019_3: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Ota-onalar uchun shaxsiy hisob yaratildi</h3>
                <p>Bizning foydalanuvchilarimizning barcha ota-onalari shaxsiy hisob orqali bolalarining muvaffaqiyatlarini osongina kuzatib borishlari mumkin. Bu bizga keraksiz yordamni keraksiz savollar berishimiz yoki shunchaki aralashmaslik uchun yordam beradi.</p>
                <img src="/images/history/is-2019-3.png" alt="history"/>
            </div>
        `,
    mar_2019_1: `
            <div>
                <h3>
                    <p>Mart</p>
                    <p>2019</p>
                </h3>
            </div>
            <div>
                <h3>MVP O'zbekiston Respublikasi Xalq ta'limi vazirligi rahbariyatiga taqdim etildi</h3>
                <p>2019 yil mart oyida biz platformani O'zbekiston Respublikasi Xalq ta'limi vaziriga, "Innovatsiyalar, texnologiyalar va strategiyalar markazi" ga, shuningdek vazirlikning markaziy apparati bo'limlari rahbarlariga taqdim etdik. Bizning mahsulotimiz mutaxassislar tomonidan yuqori baholandi, bu bizning samarali hamkorligimizga sabab bo'ldi.</p>
                <img src="/images/history/mar-2019-1-1.png" alt="history"/>
                <img src="/images/history/mar-2019-1-2.png" alt="history"/>
            </div>
        `,
    mar_2019_2: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>MVP O'zbekiston Respublikasi Xalq ta'limi vazirligi huzuridagi Respublika Ta'lim Markazi rahbariyatiga taqdim etildi</h3>
                <p>Vazirlik rahbariyatining ijobiy tavsiyalarini olib, mahsulotimiz o'quv jarayonini shakllantirishda bevosita ishtirok etadigan ixtisoslashtirilgan tashkilotga yo'lni davom ettirdi. Respublika ta’lim markazi rahbariyati bizning ishimizni yuqori baholadi va qimmatli izohlar berdi.</p>
                <img src="/images/history/mar-2019-2-1.png" alt="history"/>
                <img src="/images/history/mar-2019-2-2.png" alt="history"/>
            </div>
        `,
    mar_2019_3: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>O'zbekiston Respublikasi Xalq ta'limi vazirligi bilan o'zaro hamkorlik to'g'risida memorandum imzolandi</h3>
                <p>Bizning ishimizning natijasi bizning platformamiz orqali o'quv jarayoni sohasidagi aniq maqsad va vazifalarni belgilab beruvchi O'zbekiston Respublikasi Xalq ta'limi vazirligi bilan o'zaro hamkorlik to'g'risida memorandum imzolandi.</p>
            </div>
        `,
    oct_2019: `
            <div>
                <h3>
                    <p>Oktyabr</p>
                    <p>2019</p>
                </h3>
            </div>
            <div>
                <h3>Toshkentdagi 64 va 94-maktablarda DEMO sinovlari (2200 dan ortiq bolalar ishtirokida)</h3>
                <p>Xalq ta'limi vazirligi ko'magida bizning mahsulotimiz Toshkent shahridagi 64 va 94-maktab o'quvchilari (2200 dan ortiq bolalar) o'rtasida sinovdan o'tkazildi va bizning mahsulotimiz bilan o'tkazilgan bir qator tanlovlarda g'oliblar aniqlandi.</p>
                <img src="/images/history/oct-2019-1.jpg" alt="history"/>
                <img src="/images/history/oct-2019-2.png" alt="history"/>
                <img src="/images/history/oct-2019-3.png" alt="history"/>
            </div>
        `,
    dec_2019_1: `
            <div>
                <h3>
                    <p>Dekabr</p>
                    <p>2019</p>
                </h3>
            </div>
            <div>
                <h3>Maktabgacha ta'lim vazirligida Edu Marketning demo versiyasi</h3>
                <p>Toshkent maktablarida dasturlarimizni muvaffaqiyatli sinab ko'rgandan so'ng, Maktabgacha ta'lim vazirligi xodimlari bizning mahsulotlarimiz bilan tanishdilar.</p>
                <img src="/images/history/dec-2019-1.JPG" alt="history"/>
            </div>
        `,
    dec_2019_2: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>MDO bilan hamkorlik to'g'risida memorandum imzolandi</h3>
                <p>Maktabgacha ta'lim vazirligi mutaxassislari tomonidan mahsulotimizni chuqur o'rganish natijasida hamkorlik to'g'risida memorandum imzolandi</p>
            </div>
        `,
    feb_2020_0: `
            <div>
                <h3>
                    <p>Fevral</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Strategik hamkorlik to'g'risidagi bitim</h3>
                <p>2020 yil 5 fevralda kompaniyamiz va Xalq ta'limi vazirligi qoshidagi Innovatsiya, texnologiyalar va strategiya markazi o'rtasida strategik hamkorlik shartnomasi imzolandi. Hamkorligimiz kelgusida ta'lim sohasidagi yuqori malakali mutaxassislarni va bolalarni interfaol o'qitish usullari bo'yicha birinchi darajali mutaxassislarni jalb qiladi.</p>
                <img src="/images/history/feb-2020-0-1.JPG" alt="history"/>
                <img src="/images/history/feb-2020-0-2.JPG" alt="history"/>
            </div>
        `,
    feb_2020_1: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>PayMe bilan integratsiya</h3>
                <p>Mahsulotlarimizni bozorda muvaffaqiyatli ilgari surish va foydalanuvchilarga qulaylik yaratish maqsadida biz mamlakatimizning mashhur PayMe to'lov tizimiga qo'shildik, bu esa mahsulotlarimizdan foydalanishni yanada soddalashtirishga imkon berdi.</p>
                <img src="/images/history/feb-2020-1.png" alt="history"/>
            </div>
        `,
    feb_2020_2: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Toshkent shahridagi 60-bolalar bog'chasida mahsulotni sinovdan o'tkazish (200 dan ortiq bola)</h3>
                <p>Bizning loyihalarimizni o'rganish uchun Toshkent shahridagi 60-son bolalar bog'chasi eng kichik foydalanuvchilar orasida taqdim etildi. Mahsulotni sinab ko'rish muvaffaqiyatli o'tdi, 200 dan ortiq maktabgacha yoshdagi bolalar ishtirok etdi.</p>
                <img src="/images/history/feb-2020-2-1.jpg" alt="history"/>
                <img src="/images/history/feb-2020-2-2.jpg" alt="history"/>
            </div>
        `,
    feb_2020_3: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Rovio (Finlyandiya) kompaniyasining marketing bo'yicha direktori va mashhur Angry Birds o'yinining marketing bo'yicha direktori Piter Vesterbukga mahsulot taqdimoti</h3>
                <p>IT Parkni tashkil etish jarayonida kompaniyamiz rahbariyati va mashhur "Angry Birds" o'yinining marketing bo'yicha direktori janob P. Vesterbuk o'rtasida uchrashuv bo'lib o'tdi. Uchrashuvda Edu Market platformasi taqdim etildi va mobil o'yinlarni rivojlantirish sohasidagi professionaldan qimmatli maslahatlar olindi.</p>
                <img src="/images/history/feb-2020-3.jpg" alt="history"/>
            </div>
        `,
    feb_2020_4: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Platformaning taqdimoti va tanlov g'oliblarini katta mukofotlash</h3>
                <p>Toshkent shahridagi Inha universitetining zamonaviy anjumanlar zalida bizning platformamizning keng miqyosli taqdimoti bo'lib o'tdi, tadbirda O'zbekiston Respublikasi Xalq ta'limi vaziri, vazirlik va tegishli tashkilot rahbariyati so'zga chiqdi. Shuningdek, DEMO sinovlari o'tkazilgan maktablarning bolalariga tantanali sovg'alar topshirildi. Ushbu tadbir barcha yangiliklar saytlari va O'zbekiston milliy televizionlarida keng yoritildi.</p>
                <img src="/images/history/feb-2020-4-1.jpg" alt="history"/>
                <img src="/images/history/feb-2020-4-2.JPG" alt="history"/>
                <img src="/images/history/feb-2020-4-3.jpg" alt="history"/>
            </div>
        `,
    mar_2020: `
            <div>
                <h3>
                    <p>Mart</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Google Play-da nashr qilish</h3>
                <p>Internet foydalanuvchilariga juda ko'p miqdordagi mahsulotlarni taqdim etadigan xalqaro onlayn google pley-platformasini hamma biladi va 2020 yil mart oyida bizning mahsulotimiz ushbu global o'yin platformasida muvaffaqiyatli e'lon qilindi.</p>
                <img src="/images/history/mar-2020-1.png" alt="history"/>
                <img src="/images/history/mar-2020-2.png" alt="history"/>
            </div>
        `,
    apr_2020_1: `
            <div>
                <h3>
                    <p>Aprel</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>AppStore-ga nashr qilish</h3>
                <p>2020 yil aprel oyida mahsulotimiz boshqa AppStore dunyo savdo maydonchasida, ularni bepul sotib olish yoki yuklab olishga imkon beruvchi dastur do'konida muvaffaqiyatli nashr etildi.</p>
            </div>
        `,
    apr_2020_2: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Edu Market tariflanmagan Internet-trafikka ega ilovalar ro'yxatida!</h3>
                <p>Ilovamizning ommaviyligi va masofaviy o'qishga bo'lgan qiziqishning ortishi tufayli uyali aloqa operatorlari va Internet-provayderlar platformamizni tariflanmaydigan tarkibiy qismlar ro'yxatiga kiritdilar.</p>
            </div>
        `,
    apr_2020_3: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Edu Market ijtimoiy videolarda!</h3>
                <p>O'zbekiston Respublikasi Xalq ta'limi vazirligi rahbariyati ko'magi tufayli bizning mahsulotimiz tavsiya etilgan masofaviy o'qitish vositalari ro'yxatida bir necha bor qayd etildi. Yuqori sifatli ma'lumotni taqdim etish uchun taniqli rassomlar va unvonli sportchilar o'ynagan ijtimoiy roliklar bir necha bor televizion onlayn o'quv kanallarida namoyish etildi.</p>
            </div>
        `,
    apr_2020_4: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Edu Market talabalar uchun eng yaxshi karantin echimi sifatida</h3>
                <p>Talabalar to'rtinchi o'quv choragida butun vaqtini o'tkazishlarini hisobga olib, bizning platformamiz samarali o'qishni ta'minlashga munosib hissa qo'shdi. Bizning usulimiz yana bir bor isbotladi: gadget nafaqat vayron qiluvchi o'yinlar, balki haqiqiy o'rganish, qiziqarli va foydali.</p>
            </div>
        `,
    may_2020_1: `
            <div>
                <h3>
                    <p>May</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Biz 160 ming yuklab olish dasturining ostonasidan o'tdik!</h3>
                <p>May oyida bizning foydalanuvchilarimiz xalqaro o'yin maydonchalaridan 160,000 ta yuklab olish dasturlarini muvaffaqiyatli yakunladilar</p>
            </div>
        `,
    may_2020_2: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>60,000 kunlik dastur foydalanuvchilari!</h3>
                <p>Va oldingi harakatlarimiz natijasida biz erishdik - kompaniyaning mahsulotlarini KUN KUNI</p>
            </div>
        `,
    may_2020_3: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Click to'lov tizimi bilan integratsiya</h3>
                <p>Mahsulotimizni yanada muvaffaqiyatli targ'ib qilish uchun nafaqat PayMe, balki Click ni muvaffaqiyatli amalga oshiradigan mamlakatning etakchi to'lov tizimlari bilan integratsiyalashuv zarurati paydo bo'ldi, bu 2020 yil may oyida muvaffaqiyatli amalga oshirildi.</p>
                <img src="/images/history/feb-2020-1.png" alt="history"/>
            </div>
        `,
    jul_2020: `
            <div>
                <h3>
                    <p>Iyul</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Bolalar kontentining birinchi SNG sherigini Edu Market bozoriga ulash</h3>
                <p>Yangi xizmatning asosiy maqsadi - SNG - bu o'z mijozlariga har qanday qurilmadan Full HD (4K) va 3D formatida Internetga ulanish imkoniyatini berish. Ushbu video tarkib bilan hamkorligimiz mahsulotimizni yanada rivojlantirish va ilgari surish uchun strategik ahamiyatga ega.</p>
            </div>
        `,
    q3_2020: `
            <div>
                <h3>
                    <p>3 Kvartal</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Ucell va Beeline uyali aloqa operatorlari ulanmoqda</h3>
                <p>Respublikamizning etakchi uyali aloqa operatorlari bilan hamkorlik kompaniyamiz rivojlanishining strategik jihati va foydalanuvchilar bilan ishlashning asosiy ustuvorliklarini belgilashda katta qadamdir.</p>
            </div>
        `,
    jul_aug_2020: `
            <div>
                <h3>
                    <p>Iyul&nbsp-&nbspAvgust</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>«Edu Market» onlayn-olimpiadasida 20000&nbsp;dan ortiq maktab o'quvchilari ishtirok etdi</h3>
                <p>
                    Avval xabar berganimizdek, Xalq ta'limi vazirligi, Innovatsiya, texnologiya va strategiya markazi, hamda GameZale bilan hamkorlikda tashkil etilgan Edu Market interaktiv virtual ta'lim dasturining onlayn-olimpiadasi maktab yoshidagi bolalar o'rtasida  o'tkazilgan edi.
                    <br /><br />
                    <img src="/images/history/july-aug-2020.png" alt="history"/>
                    Android va iOS platformalari foydalanuvchilari orasida o'tkazilgan olimpiadada 12 yoshgacha bo'lgan 20 000 dan ortiq maktab o'quvchilari faol ishtirok etdi.
                    <br /><br />
                    Sarvinos Rivojhonova 1 hafta yakuni bo'yicha g'oliblikni qo'lga kiritgan bo'lsa, 2,3 va 4 hafta natijalari bo'yicha Halima Vohidova, Zuhra Akbarovna va Alibek Baxshullaev birinchilikni egallashdi.
                    <br /><br />
                    Eng yaxshi ko'rsatgich qayd etib, 1-o'rin egallagan g'oliblarga asosiy sovg’a - PLANSHET, qolgan g'oliblarga esdalik sovg'alari taqdim etiladi.
                    <br /><br />
                    G'oliblarni taqdirlash marosimi mamlakatimizdagi epidemiologik vaziyat yaxshilanganidan keyin bo'lib o'tadi.
                </p>
            </div>
        `,
    sep_2020: `
            <div>
                <h3>
                    <p>Sentyabr</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Onlayn-olimpiada g'oliblarni taqdirlash marosimi</h3>
                <img src="/images/history/sep-2020-1.jpg" alt="history"/>
                <img src="/images/history/sep-2020-2.jpg" alt="history"/>
                <img src="/images/history/sep-2020-3.jpg" alt="history"/>
                <img src="/images/history/sep-2020-4.jpg" alt="history"/>
            </div>
        `
  }
}
