import axios from 'axios'

axios.defaults.headers.common['api-version'] = '1.0'

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch
}