import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const HistoryPage = () => {
	const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
    })
    
    return (
        <section className="content pb-layout-sm">
            <div className="wrapper-header">
                <div className="container">
                    <Link to="/" className="d-inline-block title text-white text-shadow wow fadeIn">Edu Market</Link>
                </div>
            </div>
            <div className="content-wrap history">
                <h1 className="text-center title pt-layout-xs pb-layout-sm">{t('history.title')}</h1>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.sep_2018')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.feb_2019')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.is_2019_1')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.is_2019_2')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.is_2019_3')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.mar_2019_1')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.mar_2019_2')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.mar_2019_3')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.oct_2019')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.dec_2019_1')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.dec_2019_2')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.feb_2020_0')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.feb_2020_1')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.feb_2020_2')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.feb_2020_3')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.feb_2020_4')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.mar_2020')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.apr_2020_1')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.apr_2020_2')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.apr_2020_3')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.apr_2020_4')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.may_2020_1')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.may_2020_2')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.may_2020_3')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.jul_2020')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.q3_2020')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.jul_aug_2020')}}></div>
                <div className="history-group" dangerouslySetInnerHTML={{__html: t('history.sep_2020')}}></div>
            </div>
        </section>
    )
}

export default HistoryPage
