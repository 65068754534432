import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import imgMainBg from 'assets/images/bg/main.png'
import imgMain from 'assets/images/bg/main-img.png'
import imgAppStore from 'assets/images/app-store.svg'
import imgGooglePlay from 'assets/images/google-play.svg'

import imgBenefits_1 from 'assets/images/benefits/1.png'
import imgBenefits_2 from 'assets/images/benefits/2.png'
import imgBenefitsBgLeft from 'assets/images/bg/benefit-left.png'
import imgBenefitsBgRight from 'assets/images/bg/benefit-right.png'

import imgAboutBg from 'assets/images/bg/about.png'
import imgAboutThumb from 'assets/images/heroes/crocodile.png'

import imgEco1 from 'assets/images/ecosystem/1.png'
import imgEco2 from 'assets/images/ecosystem/2.png'
import imgEco3 from 'assets/images/ecosystem/3.png'
import imgEco4 from 'assets/images/ecosystem/4.png'
import imgEco5 from 'assets/images/ecosystem/5.png'
import imgEco6 from 'assets/images/ecosystem/6.png'

import imgPupilsBg from 'assets/images/bg/pupils.png'

import imgPurposesBg from 'assets/images/bg/purposes.png'
import imgApp from 'assets/images/app-icon.png'
import iconCheckGreen from 'assets/images/icons/check-green.png'

import imgSteamBg from 'assets/images/bg/steam.png'
import imgSteam from 'assets/images/steam.png'

import imgReceivesParents from 'assets/images/receives/parents.png'
import imgReceivesGovernment from 'assets/images/receives/government.png'
import imgReceivesBgLeft from 'assets/images/bg/receives-left.png'
import imgReceivesBgRight from 'assets/images/bg/receives-right.png'

import imgEmail from 'assets/images/socials/email.svg'
import imgFacebook from 'assets/images/socials/facebook.svg'
import imgInstagram from 'assets/images/socials/instagram.svg'
import imgTwitter from 'assets/images/socials/twitter.svg'
import imgVk from 'assets/images/socials/vk.svg'
import imgYoutube from 'assets/images/socials/youtube.svg'

import imgLogo from 'assets/images/logo.svg'
import imgContestRu from 'assets/images/contest-ru.svg'
import imgContestUz from 'assets/images/contest-uz.svg'
import imgLeaders from 'assets/images/contest/leaders.svg'
import imgLeaders2 from 'assets/images/contest/leaders2.svg'
import imgLeaders3 from 'assets/images/contest/leaders3.svg'
import imgLeaders4 from 'assets/images/contest/leaders4.svg'
import imgLeaders5 from 'assets/images/contest/leaders5.svg'
import imgLeaders6 from 'assets/images/contest/leaders6.svg'
import imgLeaders7 from 'assets/images/contest/leaders7.svg'
import imgLeaders8 from 'assets/images/contest/leaders8.svg'
import imgSeedstars from 'assets/images/seedstars_contest.png'

const CONTEST_DAY = new Date('Oct 26, 2020 0:00:00')

const Home = () => {
  const { t, i18n } = useTranslation()
  const [selectedOption, setSelectedOption] = useState({
    value: '1',
    label: t('contest.week_1')
  })
  const [time, setTime] = useState(undefined)
  const [, setContestEndTime] = useState(undefined)

  useEffect(() => {
    setInterval(function () {
      const oneDay = 24 * 60 * 60 * 1000
      const nowDate = new Date()
      const leftDate = new Date(CONTEST_DAY)

      var diffDays = Math.floor(Math.abs((nowDate - leftDate) / oneDay))

      var countDownDate = new Date(CONTEST_DAY)
      let now = new Date().getTime()

      let distance = countDownDate.getTime() - now

      if (distance < 0) {
        setTime('over')
        // const CONTEST_END_DATE = new Date('Dec 21, 2020 0:00:00')
        const CONTEST_END_DATE = new Date('Dec 21, 2020 0:00:00')
        diffDays = Math.floor(
          Math.abs((new Date() - CONTEST_END_DATE) / (24 * 60 * 60 * 1000))
        )
        countDownDate = new Date(CONTEST_END_DATE)
        distance = countDownDate.getTime() - now

        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        let seconds = Math.floor((distance % (1000 * 60)) / 1000)

        return setContestEndTime({ days: diffDays, hours, minutes, seconds })
      }

      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      let seconds = Math.floor((distance % (1000 * 60)) / 1000)

      return setTime({ days: diffDays, hours, minutes, seconds })
    }, 1000)
  }, [time])

  const contestSelectOptions = [
    { value: '1', label: t('contest.week_1') },
    { value: '2', label: t('contest.week_2') },
    { value: '3', label: t('contest.week_3') },
    { value: '4', label: t('contest.week_4') },
    { value: '5', label: t('contest.week_5') },
    { value: '6', label: t('contest.week_6') },
    { value: '7', label: t('contest.week_7') },
    { value: '8', label: t('contest.week_8') }
  ]

  const handleSelectChange = selectedOption => {
    setSelectedOption(selectedOption)
  }

  return (
    <React.Fragment>
      <main className="main mb-layout-sm">
        <img src={imgMainBg} className="main__bg" alt="background" />

        <div className="container relative">
          <div className="main__contest">
            <img src={imgMain} className="main__img" alt="background" />
            <div className="main__contest-info">
              <h2 className="main__contest-title">{t('contest.info_1')}</h2>
              {!time ? (
                ''
              ) : time === 'over' ? (
                <div className="wow fadeIn pt-xl">
                <h2 className="title title--sm text-center">{ t('contest.end') }</h2>
                <Link to="/contest" className="btn btn--green btn--outer-shadow mt-lg">{ t('more') }</Link>
              </div>
              ) : (
                <div className="wow fadeIn">
                  {/* <h2 className="title title--sm text-center text-underline">{ t('contest.started') }</h2> */}
                  <h2 className="title title--sm text-center">
                    {t('contest.left')}
                  </h2>
                  {time && (
                    <div className="contest__countdown">
                      <div className="contest__countdown-item">
                        {time.days} {t('days')}
                      </div>
                      <div className="contest__countdown-item">
                        {time.hours} {t('hours')}
                      </div>
                      <div className="contest__countdown-item">
                        {time.minutes} {t('minutes')}
                      </div>
                      <div className="contest__countdown-item">
                        {time.seconds} {t('seconds')}
                      </div>
                    </div>
                  )}
                  <Link
                    to="/contest"
                    className="btn btn--green btn--outer-shadow mt-lg"
                  >
                    {t('more')}
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div className="main-info text-shadow">
            <Link
              to="/"
              className="d-inline-block title font-black pb-xl wow fadeInUp"
            >
              Edu Market
            </Link>
            <p
              className="text-xl pb-layout-xs wow fadeInUp"
              data-wow-delay="0.1s"
            >
              {t('main')}
            </p>
            <div className="app-actions wow fadeInUp" data-wow-delay="0.2s">
              <a
                href="https://play.google.com/store/apps/details?id=com.gamezale.edumarket"
                rel="noopener noreferrer"
                alt="google play"
                target="_blank"
              >
                <img src={imgGooglePlay} alt="GooglePlay" />
              </a>
              <a
                href="https://apps.apple.com/us/app/edu-market/id1504756814?l=ru&ls=1"
                rel="noopener noreferrer"
                alt="google play"
                target="_blank"
              >
                <img src={imgAppStore} alt="AppStore" />
              </a>
            </div>
            <a
              href="https://edumarket.uz/files/edu_market.apk"
              rel="noopener noreferrer"
              className="btn btn--white-outline btn--thick-border btn--md d-inline-block mt-sm wow fadeInUp"
              target="_blank"
              data-wow-delay="0.2s"
              download
            >
              {t('downloadFromSite')}
              <span className="d-block">Tas-ix</span>
            </a>
          </div>
        </div>
        <Link
          to="/contest"
          className="main__contest-sm wow fadeIn"
          data-wow-delay="0.4s"
        >
          <img
            src={i18n.language === 'ru' ? imgContestRu : imgContestUz}
            alt="Contest"
          />
        </Link>
      </main>

      {/* <div id="yandex_rtb_R-A-657897-1" style={{ marginBottom: 40 }}></div> */}
      <div className="container">
        <div className="visible-md-block">
          {!time ? (
            ''
          ) : time === 'over' ? (
            <div className="wow fadeIn">
              <h2 className="title title--sm text-center text-danger pb-md">
                {t('contest.end')}
              </h2>
              <div className="text-center pb-xl">
                <Link
                  to="/contest"
                  className="btn btn--green btn--outer-shadow"
                >
                  {t('more')}
                </Link>
              </div>
            </div>
          ) : (
            <div className="wow fadeIn">
              {/* <h2 className="title title--sm text-center text-danger">{ t('contest.started') }</h2> */}
              <h2 className="title title--sm text-center">
                {t('contest.left')}
              </h2>
              {time && (
                <div className="contest__countdown">
                  <div className="contest__countdown-item">
                    {time.days} {t('days')}
                  </div>
                  <div className="contest__countdown-item">
                    {time.hours} {t('hours')}
                  </div>
                  <div className="contest__countdown-item">
                    {time.minutes} {t('minutes')}
                  </div>
                  <div className="contest__countdown-item">
                    {time.seconds} {t('seconds')}
                  </div>
                </div>
              )}
              <div className="text-center pb-xl">
                <Link
                  to="/contest"
                  className="btn btn--green btn--outer-shadow"
                >
                  {t('more')}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* {time && time !== 'over' && <div className="container">
				<div className="contest-info">
					<div className="contest-info__text">
						<p>{ t('contest.sud_info') }</p>
						<Link to="/leaderboard" className="btn btn--green mt-xl btn--outer-shadow">{ t('more') }</Link>
					</div>
				</div>
			</div>} */}

      <div className="contest-leaders">
        <div className="container relative z-index-1">
          <h2
            className="title title--md pb-layout-xs text-center"
            dangerouslySetInnerHTML={{
              __html: t('contest.old_stage_completed')
            }}
          ></h2>
          <Select
            isSearchable={false}
            value={selectedOption}
            onChange={handleSelectChange}
            options={contestSelectOptions}
            placeholder="Promotion Type"
            className="react-select-container contest-leaders__select"
            classNamePrefix="react-select"
          />
          {selectedOption.value === '1' && (
            <React.Fragment>
              <img
                src={imgLeaders}
                className="contest-leaders__thumb wow fadeInUp"
                alt="leaders"
              />
              <div
                className="contest-leaders-wrap wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">4</p>
                  <div>
                    <h6>Mubina Xomidova</h6>
                    <p>ID1235879</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">5</p>
                  <div>
                    <h6>Ulugbek Kenjayev</h6>
                    <p>ID1117561</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">6</p>
                  <div>
                    <h6>Даниал Жамиев</h6>
                    <p>ID1237348</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">7</p>
                  <div>
                    <h6>Navbahor Farmonov</h6>
                    <p>ID1239959</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">8</p>
                  <div>
                    <h6>Fazliddin Muhiddinhojaev</h6>
                    <p>ID1185851</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">9</p>
                  <div>
                    <p>ID1237984</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">10</p>
                  <div>
                    <p>ID1234425</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {selectedOption.value === '2' && (
            <React.Fragment>
              <img
                src={imgLeaders2}
                className="contest-leaders__thumb wow fadeInUp"
                alt="leaders"
              />
              <div
                className="contest-leaders-wrap wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">4</p>
                  <div>
                    <h6>Alibek Baxshullayev</h6>
                    <p>1240809</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">5</p>
                  <div>
                    <h6>Muhammadjon Rahimov</h6>
                    <p>1196129</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">6</p>
                  <div>
                    <h6>Farux f</h6>
                    <p>1239959</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">7</p>
                  <div>
                    <h6>Solihabegim Hayotova</h6>
                    <p>1240034</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">8</p>
                  <div>
                    <h6>Salima Xalilova</h6>
                    <p>1239495</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">9</p>
                  <div>
                    <h6>Asilbek Mamarahimov</h6>
                    <p>1093904</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">10</p>
                  <div>
                    <p>1211508</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {selectedOption.value === '3' && (
            <React.Fragment>
              <img
                src={imgLeaders3}
                className="contest-leaders__thumb wow fadeInUp"
                alt="leaders"
              />
              <div
                className="contest-leaders-wrap wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">4</p>
                  <div>
                    <h6>Muhammadjon Rahimov</h6>
                    <p>ID1196129</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">5</p>
                  <div>
                    <h6>Soledat Alimbaeva</h6>
                    <p>ID1245951</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">6</p>
                  <div>
                    <h6>Виктор Шарипов</h6>
                    <p>ID1245225</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">7</p>
                  <div>
                    <h6>Abdulaziz Mamadiyorov</h6>
                    <p>ID1244659</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">8</p>
                  <div>
                    <h6>Sardor Rahmatjonov</h6>
                    <p>ID1238524</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">9</p>
                  <div>
                    <h6>Islombek Akramov</h6>
                    <p>ID1243999</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">10</p>
                  <div>
                    <h6>Salima Xalilova</h6>
                    <p>ID1239495</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {selectedOption.value === '4' && (
            <React.Fragment>
              <img
                src={imgLeaders4}
                className="contest-leaders__thumb wow fadeInUp"
                alt="leaders"
              />
              <div
                className="contest-leaders-wrap wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">4</p>
                  <div>
                    <h6>Тахмина Бобоева</h6>
                    <p>ID1249192</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">5</p>
                  <div>
                    <p>ID1248710</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">6</p>
                  <div>
                    <h6>Salohiddin Jurayev</h6>
                    <p>ID1119639</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">7</p>
                  <div>
                    <h6>Jamshid Baxshullayev</h6>
                    <p>ID1240112</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">8</p>
                  <div>
                    <p>ID1246317</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">9</p>
                  <div>
                    <h6>Islom Akramov</h6>
                    <p>ID1248107</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">10</p>
                  <div>
                    <h6>Polina Mamedova</h6>
                    <p>ID1227403</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {selectedOption.value === '5' && (
            <React.Fragment>
              <img
                src={imgLeaders5}
                className="contest-leaders__thumb wow fadeInUp"
                alt="leaders"
              />
              <div
                className="contest-leaders-wrap wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">4</p>
                  <div>
                    <h6>Islombek ismoilov</h6>
                    <p>ID1249068</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">5</p>
                  <div>
                    <h6>Abbos Aliyev</h6>
                    <p>ID1249875</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">6</p>
                  <div>
                    <p>ID1229738</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">7</p>
                  <div>
                    <h6>Тахмина Бобоева</h6>
                    <p>ID1251338</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">8</p>
                  <div>
                    <h6>Salohiddin Jurayev</h6>
                    <p>ID1119639</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">9</p>
                  <div>
                    <p>ID1253427</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">10</p>
                  <div>
                    <p>ID1250429</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {selectedOption.value === '6' && (
            <React.Fragment>
              <img
                src={imgLeaders6}
                className="contest-leaders__thumb wow fadeInUp"
                alt="leaders"
              />
              <div
                className="contest-leaders-wrap wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">4</p>
                  <div>
                    <h6>Islombek akramov</h6>
                    <p>ID1254346</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">5</p>
                  <div>
                    <h6>Omina Qosimova</h6>
                    <p>ID1255520</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">6</p>
                  <div>
                    <h6>Muhsinaxon Isroilova</h6>
                    <p>ID1246628</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">7</p>
                  <div>
                    <p>ID1254178</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">8</p>
                  <div>
                    <h6>Malika Baxriddinova</h6>
                    <p>ID1067783</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">9</p>
                  <div>
                    <h6>ismoil yoldoshev</h6>
                    <p>ID1256082</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">10</p>
                  <div>
                    <h6>Мухаммад Мухамедов</h6>
                    <p>ID1197706</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {selectedOption.value === '7' && (
            <React.Fragment>
              <img
                src={imgLeaders7}
                className="contest-leaders__thumb wow fadeInUp"
                alt="leaders"
              />
              <div
                className="contest-leaders-wrap wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">4</p>
                  <div>
                    <h6>Axror Abduganiyev</h6>
                    <p>1256598</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">5</p>
                  <div>
                    <h6>Maxsuma Baxriddinova</h6>
                    <p>1253530</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">6</p>
                  <div>
                    <h6>Muhsinaxon Isroilova</h6>
                    <p>1246628</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">7</p>
                  <div>
                    <p>1257975</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">8</p>
                  <div>
                    <h6>Islombek akramov</h6>
                    <p>1256672</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">9</p>
                  <div>
                    <h6>Elbek Ramazonov</h6>
                    <p>1257374</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">10</p>
                  <div>
                    <h6>Bexruz Ziyodullayev</h6>
                    <p>1254344</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {selectedOption.value === '8' && (
            <React.Fragment>
              <img
                src={imgLeaders8}
                className="contest-leaders__thumb wow fadeInUp"
                alt="leaders"
              />
              <div
                className="contest-leaders-wrap wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">4</p>
                  <div>
                    <h6>Diyora Juraeva</h6>
                    <p>1251359</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">5</p>
                  <div>
                    <p>1259756</p>
                  </div>
                </div>
                <div className="contest-leaders__item contest-leaders__item--x3">
                  <p className="contest-leaders__item-num">6</p>
                  <div>
                    <h6>Bexruz Ziyodullayev</h6>
                    <p>1254344</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">7</p>
                  <div>
                    <h6>Islombek akramov</h6>
                    <p>1260957</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">8</p>
                  <div>
                    <h6>Islombek akramov</h6>
                    <p>1256672</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">9</p>
                  <div>
                    <p>1229738</p>
                  </div>
                </div>
                <div className="contest-leaders__item">
                  <p className="contest-leaders__item-num">10</p>
                  <div>
                    <h6>Poʻlatjon Ramazonov</h6>
                    <p>1259668</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>

      <div className="container text-center py-layout-sm">
        <p className="text-lg pb-xl">{t('seedstars_contest')}</p>
        <img src={imgSeedstars} style={{maxWidth: 850}} className="full-width" alt="contest_results"/>
        <div className="pt-lg">
          <a href="https://www.seedstarsworld.com/event/seedstars-tashkent-2020/"
            className="btn btn--green btn--outer-shadow" target="_blank" rel="noopener noreferrer">
            {t('more')}
          </a>
        </div>
      </div>

      <div className="benefits">
        <img
          src={imgBenefitsBgLeft}
          className="benefits__bg benefits__bg--left"
          alt="benefit box"
        />
        <img
          src={imgBenefitsBgRight}
          className="benefits__bg benefits__bg--right"
          alt="benefit box"
        />
        <div className="container">
          <h2 className="title text-center benefits__title wow fadeIn">
            {t('benefit.title')}
          </h2>
          <div className="benefits__card benefits__card--first">
            <img
              src={imgBenefits_1}
              className="benefits__card-img wow fadeIn"
              alt="benefit"
            />
            <p
              className="benefits__card-text text-xl wow fadeIn"
              data-wow-delay="0.2s"
            >
              {t('benefit.info_1')}
            </p>
          </div>
          <div className="benefits__card benefits__card--reversed">
            <p
              className="benefits__card-text text-xl wow fadeIn"
              data-wow-delay="0.2s"
            >
              {t('benefit.info_2')}
            </p>
            <img
              src={imgBenefits_2}
              className="benefits__card-img wow fadeIn"
              alt="benefit"
            />
          </div>
        </div>
      </div>

      <div className="about">
        <img src={imgAboutBg} className="about__bg" alt="background" />
        <div className="container relative">
          <div className="about-info text-shadow">
            <h2 className="title about__title pb-xl wow fadeInUp">
              {t('call.title')}
            </h2>
            <p
              className="text-xl about__text pb-layout-xs wow fadeInUp"
              data-wow-delay="0.1s"
            >
              {t('call.info')}
            </p>
            <div
              className="app-actions app-actions--vertical-mobile wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.gamezale.edumarket"
                rel="noopener noreferrer"
                alt="google play"
                target="_blank"
              >
                <img src={imgGooglePlay} alt="GooglePlay" />
              </a>
              <a
                href="https://apps.apple.com/us/app/edu-market/id1504756814?l=ru&ls=1"
                rel="noopener noreferrer"
                alt="google play"
                target="_blank"
              >
                <img src={imgAppStore} alt="AppStore" />
              </a>
            </div>
            <a
              href="https://edumarket.uz/files/edu_market.apk"
              rel="noopener noreferrer"
              className="btn btn--white-outline btn--md btn--thick-border d-inline-block mt-sm wow fadeInUp"
              target="_blank"
              data-wow-delay="0.2s"
              download
            >
              {t('downloadFromSite')}
              <span className="d-block">Tas-ix</span>
            </a>
          </div>
          <img
            src={imgAboutThumb}
            alt="crocodile"
            className="about__thumb wow fadeIn"
            data-wow-delay="0.3s"
          />
        </div>
      </div>

      <div className="ecosystem">
        <div className="container">
          <h2 className="title text-center pb-layout-sm wow fadeIn">
            {t('eco.main_title')}
          </h2>
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="ecosystem__item wow fadeIn" data-wow-delay="0.1s">
                <div className="ecosystem__item-thumb">
                  <img src={imgEco1} alt={t('eco.title_1')} />
                </div>
                <h3 className="text-md">{t('eco.title_1')}</h3>
                <p>{t('eco.info_1')}</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="ecosystem__item wow fadeIn" data-wow-delay="0.2s">
                <div className="ecosystem__item-thumb">
                  <img src={imgEco2} alt={t('eco.title_2')} />
                </div>
                <h3 className="text-md">{t('eco.title_2')}</h3>
                <p dangerouslySetInnerHTML={{ __html: t('eco.info_2') }}></p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="ecosystem__item wow fadeIn" data-wow-delay="0.3s">
                <div className="ecosystem__item-thumb">
                  <img src={imgEco3} alt={t('eco.title_3')} />
                </div>
                <h3 className="text-md">{t('eco.title_3')}</h3>
                <p>{t('eco.info_3')}</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="ecosystem__item wow fadeIn" data-wow-delay="0.4s">
                <div className="ecosystem__item-thumb">
                  <img src={imgEco4} alt={t('eco.title_4')} />
                </div>
                <h3 className="text-md">{t('eco.title_4')}</h3>
                <p dangerouslySetInnerHTML={{ __html: t('eco.info_4') }}></p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="ecosystem__item wow fadeIn" data-wow-delay="0.5s">
                <div className="ecosystem__item-thumb">
                  <img src={imgEco5} alt={t('eco.title_5')} />
                </div>
                <h3 className="text-md">{t('eco.title_5')}</h3>
                <p>{t('eco.info_5')}</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="ecosystem__item wow fadeIn" data-wow-delay="0.6s">
                <div className="ecosystem__item-thumb">
                  <img src={imgEco6} alt={t('eco.title_6')} />
                </div>
                <h3 className="text-md">{t('eco.title_6')}</h3>
                <p>{t('eco.info_6')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pupils mb-layout-xs mt-xl">
        <img src={imgPupilsBg} className="pupils__bg" alt="pupils" />
        <div className="container relative">
          <h2 className="title text-center pb-layout-sm wow fadeIn">
            {t('pupils.title')}
          </h2>
          <div className="row">
            <div className="col-md-6">
              <ul className="pupils__list text-lg text-shadow wow fadeIn">
                <li>{t('pupils.info_1')}</li>
                <li>{t('pupils.info_2')}</li>
                <li>{t('pupils.info_3')}</li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul className="pupils__list text-lg text-shadow wow fadeIn">
                <li>{t('pupils.info_4')}</li>
                <li>{t('pupils.info_5')}</li>
                <li>{t('pupils.info_6')}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="purposes mb-layout-sm">
        <div className="container purposes-container">
          <img src={imgPurposesBg} className="purposes__bg" alt="bg" />
          <img
            src={imgApp}
            className="purposes__app-thumb wow fadeIn"
            data-wow-delay="0.3s"
            alt="app"
          />
          <h2 className="title text-center pb-layout-sm wow fadeInUp">
            {t('goal.title')}
          </h2>
          <div className="row">
            <div className="col-md-6">
              <div
                className="purposes__item wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img src={iconCheckGreen} alt="check" />
                <p className="text-md">{t('goal.info_1')}</p>
              </div>
              <div
                className="purposes__item wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img src={iconCheckGreen} alt="check" />
                <p className="text-md">{t('goal.info_2')}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="purposes__item wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img src={iconCheckGreen} alt="check" />
                <p className="text-md">{t('goal.info_3')}</p>
              </div>
              <div
                className="purposes__item wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <img src={iconCheckGreen} alt="check" />
                <p className="text-md">{t('goal.info_4')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="steam mb-layout-sm">
        <div className="container steam-container">
          <img src={imgSteamBg} className="steam__bg" alt="bg" />
          <div className="relative z-index-1 text-white text-shadow">
            <h2 className="title text-center wow fadeInUp">
              {t('steam.title')}
            </h2>
            <img
              src={imgSteam}
              className="steam__thumb wow fadeInUp"
              data-wow-delay="0.1s"
              alt="steam"
            />
            <p
              className="steam__text text-lg wow fadeInUp"
              data-wow-delay="0.2s"
            >
              {t('steam.info')}
            </p>
          </div>
        </div>
      </div>

      <div className="receives">
        <img
          src={imgReceivesBgLeft}
          className="receives__bg receives__bg--left"
          alt="bg"
        />
        <img
          src={imgReceivesBgRight}
          className="receives__bg receives__bg--right"
          alt="bg"
        />
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="receives-spacer-right">
                <img
                  src={imgReceivesParents}
                  className="receives__thumb receives__thumb--left wow fadeIn"
                  alt="parents"
                />
                <h3
                  className="text-xl pb-layout-xs font-bold wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  {t('parents.title')}
                </h3>
                <ul
                  className="list list--check-blue receives__list wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <li>{t('parents.info_1')}</li>
                  <li>{t('parents.info_2')}</li>
                  <li>{t('parents.info_3')}</li>
                  <li>{t('parents.info_4')}</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="receives-spacer">
                <img
                  src={imgReceivesGovernment}
                  className="receives__thumb receives__thumb--right wow fadeIn"
                  alt="parents"
                />
                <h3
                  className="text-xl pb-layout-xs font-bold wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  {t('government.title')}
                </h3>
                <ul
                  className="list list--check-blue receives__list wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <li>{t('government.info_1')}</li>
                  <li>{t('government.info_2')}</li>
                  <li>{t('government.info_3')}</li>
                  <li>{t('government.info_4')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app-actions justify-content-center mt-lg pb-layout-sm wow fadeInUp flex-wrap">
        <a
          href="https://play.google.com/store/apps/details?id=com.gamezale.edumarket"
          rel="noopener noreferrer"
          alt="google play"
          className="mb-sm"
          target="_blank"
        >
          <img src={imgGooglePlay} alt="GooglePlay" />
        </a>
        <a
          href="https://apps.apple.com/us/app/edu-market/id1504756814?l=ru&ls=1"
          rel="noopener noreferrer"
          alt="google play"
          className="mb-sm"
          target="_blank"
        >
          <img src={imgAppStore} alt="AppStore" />
        </a>
        <a
          href="https://edumarket.uz/files/edu_market.apk"
          rel="noopener noreferrer"
          className="btn btn--default-outline btn--md btn--thick-border mb-sm"
          target="_blank"
          download
        >
          {t('downloadFromSite')}
          <span className="d-block">Tas-ix</span>
        </a>
      </div>

      <footer className="footer">
        <div className="container">
          <p
            className="b-b mb-lg pb-sm wow fadeIn"
            dangerouslySetInnerHTML={{ __html: t('footer.contact_us') }}
          ></p>
          <div className="footer-wrap">
            <div className="footer-group">
              <div>
                <p className="wow fadeIn" data-wow-delay="0.1">
                  Edu Market &copy; 2020.
                </p>
                <p className="wow fadeIn" data-wow-delay="0.2">
                  {t('footer.rights')}
                </p>
              </div>
              <div>
                <p>
                  <Link
                    to="/partners"
                    className="link wow fadeIn"
                    data-wow-delay="0.3s"
                  >
                    {t('partners.title')}
                  </Link>
                </p>
                <p>
                  <Link
                    to="/privacy"
                    className="link wow fadeIn"
                    data-wow-delay="0.4s"
                  >
                    {t('footer.policy')}
                  </Link>
                </p>
                <p>
                  <Link
                    to="/terms"
                    className="link wow fadeIn"
                    data-wow-delay="0.5s"
                  >
                    {t('footer.terms')}
                  </Link>
                </p>
              </div>
            </div>
            <div className="text-right">
              <div className="socials wow fadeIn" data-wow-delay="0.6s">
                <a href="mailto:developer@gamezale.com">
                  <img src={imgEmail} alt="email" />
                </a>
                <a
                  href="https://www.instagram.com/edumarketuz/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={imgInstagram} alt="instagram" />
                </a>
                <a
                  href="https://www.facebook.com/Edu MarketUz/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={imgFacebook} alt="facebook.svg" />
                </a>
                <a
                  href="https://vk.com/gamezale"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={imgVk} alt="vk.svg" />
                </a>
                <a
                  href="https://twitter.com/gamezalestudio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={imgTwitter} alt="twitter.svg" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCc5MLA0yDw2flO05hWacR3A/videos"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={imgYoutube} alt="youtube.svg" />
                </a>
              </div>
              <a
                href="https://gamezale.uz/"
                target="_blank"
                rel="noopener noreferrer"
                className="wow fadeIn"
                data-wow-delay="0.7s"
              >
                <img src={imgLogo} className="footer__devs" alt="GameZale" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Home
