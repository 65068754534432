import React from 'react'
import { useTranslation } from 'react-i18next'

const Preloader = ({ normal = false }) => {
	const { t } = useTranslation()
	
	return (
		<div className={normal ? 'preloader preloader--normal' : 'preloader'}>
			<div className="box1"></div>
			<div className="box2"></div>
			<div className="box3"></div>
			<p>{ t('loading') }...</p>
		</div>
	)
}

export default Preloader
