import React, { useEffect, useState } from 'react'
import { secondsToHms } from 'utilities/timeConverter'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getLeaders } from 'services/apiService'
import Preloader from './Preloader'

import imgItsm from 'assets/images/itsm.png'
import imgThumbLeft from 'assets/images/benefits/1.png'
import imgThumbRight from 'assets/images/benefits/2.png'

const CONTEST_DAY = new Date('Jul 20, 2020 0:00:00')

const Leaderboard = () => {
	const [results, setResults] = useState([])
	const [pageNumber, setPageNumber] = useState(1)
	const [isLoading, setIsLoading] = useState(true)
	const { t, i18n } = useTranslation()
	const [time, setTime] = useState(undefined)

	useEffect(() => {

		var x = setInterval(function() {
			
			var countDownDate = new Date(CONTEST_DAY)
			let now = new Date().getTime()
			
			let distance = countDownDate.getTime() - now

			if (distance < 0) {
				setTime('over')
				clearInterval(x)
			}
			else {
				setTime('not')
			}
		}, 1000)

		fetchResults()
		document.querySelector('body').style.backgroundColor = '#F8F8FB'
		// eslint-disable-next-line
	}, [])

	async function fetchResults(pageNum = 1, pageSize = 25) {
		const results = await getLeaders(pageNum, pageSize).then(res => res.data.data)

		results.forEach((el, index) => {
			el.placeNumber = index + 1 + (pageNum * pageSize) - pageSize
			el.totalSpentTime = secondsToHms(el.spentTime, i18n.language)
		})

		setResults(results)
		setIsLoading(false)
		window.scrollTo({
			behavior: 'smooth',
			top: 0
		})
	}

	function handleToPrevPage() {
		setPageNumber(pageNumber - 1)
		setIsLoading(true)
		fetchResults(pageNumber - 1)
	}

	function handleToNextPage() {
		setPageNumber(pageNumber + 1)
		setIsLoading(true)
		fetchResults(pageNumber + 1)
	}
	
	function generateRowClass(i) {
		if (i === 1) return 'results-row highlighted first'
		else if (i === 2) return 'results-row highlighted second'
		else if (i === 3) return 'results-row highlighted third'
		else return 'results-row'
	}
	
	return (
		<div className="results">
			<img src={imgThumbLeft} className="results__thumb results__thumb--left" alt="img"/>
			<img src={imgThumbRight} className="results__thumb results__thumb--right" alt="img"/>
			<div className="container relative">
				<div className="d-flex justify-content-between align-items-center">
					<Link to="/" className="title title--logo font-black text-green"><span className="text-orange">Edu</span>Market</Link>
					<img src={imgItsm} className="flex-shrink-0" alt="ITSM"/>
				</div>
				{time && time === 'not'
					&& <div className="contest-info contest-info--sm mt-layout-sm wow fadeInUp">
						<div className="contest-info__text">
							<p className="pb-layout-xs">{ t('contest.sud_info_1') }</p>
							<p className="pb-layout-xs">{ t('contest.sud_info_2') }</p>
							<p>{ t('contest.sud_info_3') }</p>
						</div>
					</div>
				}
				{time && time === 'over' && <React.Fragment>
						<div className="results-wrap">
							<div className="results-row text">
								<p className="results-row__item results-row__item--no-bg">№</p>
								<p className="results-row__item">{ t('results.player_id') }</p>
								<p className="results-row__item">{ t('results.points') }</p>
								{/* <p className="results-row__item">{ t('results.spent_time') }</p>  */}
							</div>
							{isLoading
								? <Preloader />
								: results.map(i => <div className={generateRowClass(i.placeNumber)} key={i.peopleId}>
									<div className="results-row__inner">
										<p className="results-row__item"><span>{i.placeNumber}</span></p>
										<p className="results-row__item">{i.peopleId}</p>
										<p className="results-row__item">{i.score}</p>
										{/* <p className="results-row__item">{secondsToHms(i.spentTime, i18n.language)}</p>  */}
									</div>
								</div>)
							}
							{results && results.length === 0 && <p className="py-layout-sm text-center wow fadeIn">{ t('contest.soon_stats') }</p>}
						</div>
						<div className="text-center">
							<button className="btn btn--default-outline btn--no-text-shadow mr-md"
								onClick={handleToPrevPage} disabled={pageNumber <= 1 || isLoading}>&larr;</button>
							<button className="btn btn--default-outline btn--no-text-shadow"
								onClick={handleToNextPage} disabled={isLoading}>&rarr;</button>
						</div>
					</React.Fragment>
				}
			</div>
		</div>
	)
}

export default Leaderboard
