import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const PrivacyPage = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
    })
    
    return (
        <section class="content pb-layout-sm">
            <div className="wrapper-header">
                <div className="container">
                    <Link to="/" className="d-inline-block title text-white text-shadow wow fadeIn">Edu Market</Link>
                </div>
            </div>
            <div class="content-wrap">
                <div class="redactor-content py-layout-xs">

                    <h3 class="title text-center mb-layout-xs wow fadeInUp">Privacy Policy</h3>

                    <div class="wow fadeInUp" data-wow-delay="0.1s">
                        <p><span class="font-bold">LLC GAME ZALE PRIVACY POLICY (Last Updated on November 11th, 2019)</span></p>
                        
                        <div class="pb-layout-xs">
                            <p>
                                LLC GAME ZALE (“Edu Market”) respects your privacy. This notice describes Edu Market privacy policy for the website at www.Edu Market.uz (the "Website") and mobile and PC apps distributed by Edu Market (the “Apps,” together with the Website, the “Edu Market Products”). We may revise this privacy policy at any time, and the date of last revision will be available at the top of this page.
                                <span class="font-bold">By visiting the Website or using the Apps, you understand and accept this privacy policy. Your continued use of the
                                Edu Market Products after changes have been posted to the privacy policy will constitute your acceptance of such changes.</span>
                            </p>
                        </div>
                    </div>

                    <div class="pb-layout-xs wow fadeInUp" data-wow-delay="0.2s">
                        <p class="font-bold">What information we collect, in general:</p>

                        <p>There are two types of users whose data we are collecting: children and their parents (or other guardians). “You” for the purposes of this privacy policy means a parent (or other guardian of a child) using Edu Market Products.</p>

                        <p>Edu Market may ask you for and collect from you personal data depending on the information and/or services you request. Once you have provided your personal data, you are not anonymous to Edu Market.</p>

                        <p>You can choose not to provide us with certain information, but then you may not be able to take advantage of many of our special features. The personal data you provide is used for such purposes as answering questions, sending product updates, and communicating with you about Edu Market products and services, including specials and new features. The children's personal data you provide to us shall only be used for creating your individual account (which will allow you to receive progress reports that include tracking your child's performance and progress). You may modify or remove your personal data or children's data identified below at any time by logging into your account and accessing features to edit your profile and/or account information.</p>

                        <p>We attempt to limit access to blogs, forums, and other mechanisms of sharing content by requiring your user profile and password to upload and share content. We cannot, however, control the actions of you or others. If someone chooses to share their username and password, it is possible a minor might gain access to such forums and view or share content.</p>
                    </div>

                    <div class="wow fadeInUp">
                        <p class="font-bold">What information we collect, in particular:</p>

                        <p>
                            <span class="font-bold">Children: </span>
                            Edu Market does not seek to collect any personally identifiable information directly from children under the age of 13. If you are under 13, please do not submit any personally identifiable information to Edu Market. We additionally note that (i) any personally identifiable information or data that is inadvertently collected from a child will be deleted at the request of the parent or guardian, and (ii) should personally identifiable information or data be collected from a child, such information or data will only be used for educational purposes.
                        </p>

                        <p>Depending on the category of a user, Edu Market collects following information:</p>

                        <div>
                            <p class="font-bold">a) Data related to children:</p>

                            <p><i>Necessary:</i></p>
                            <ul class="mb-xl">
                                <li>Child’s Nickname (for creating personalised profile);</li>
                                <li>year of the birth (for providing age-related personalised content);</li>
                            </ul>

                            <p><i>Optional:</i></p>
                            <ul class="mb-xl">
                                <li>First name and surname (for creating personalised profile);</li>
                                <li>country and city (for providing region-specific content);</li>
                                <li>identification of a school, for children enrolled in the school (for gathering feedback on the school educational
                                programme);</li>
                                <li>gender (for analysing educational statistics).</li>
                            </ul>
                        </div>

                        <div>
                            <p class="font-bold">b) Data related to parents (guardians):</p>
                        
                            <p><i>Necessary:</i></p>
                            <ul class="mb-xl">
                                <li>Your Nickname or Name and Surname (for signing in in the personalised profile);</li>
                            </ul>
                        
                            <p><i>Optional:</i></p>
                            <ul class="mb-xl">
                                <li>Your mobile number or email (for resetting your password, for signing in the personalised profile);</li>
                                <li>details of a credit card (for providing access to the paid content).</li>
                            </ul>
                        </div>
                    </div>

                    <div class="wow fadeInUp">
                        <p class="pt-lg"><span class="font-bold">Facebook registration data.</span> Instead of sign in directly in the App or on the Website, you may register with your Facebook profile. In this case, we will collect your full name (First name and surname) and date of birth, as it appears on your Facebook profile.</p>

                        <p>Edu Market also collects information about the device used to play our Apps, such as IP addresses, operating system, device type, and versions of our Apps you are using. Edu Market may also track, archive, and use information regarding your use of the Apps as provided herein, including information about level of usage and particular features used. This data is used to more efficiently operate Edu Market’s business, promote our products and services and administer the Edu Market Products.</p>

                        <p>This type of aggregate data enables us to figure out how often customers use our products and services so that we can make them appealing to as many customers as possible, and improve those products and services accordingly. As part of this use of information, we may provide aggregate information to our partners about how our customers, collectively, use our products and services. We share this type of statistical data so that our partners also understand how often people use products and services, so that they, too, may provide you with an optimal online experience. Again, we do not disclose aggregate information to a partner in a manner that would identify you or your child personally.</p>
                    </div>

                    <div class="wow fadeInUp">
                        <p class="font-bold">How Edu Market uses gathered data:</p>
                        <ul class="mb-layout-xs">
                            <li>To provide a child with personalised content;</li>
                            <li>To process and respond to enquiries;</li>
                            <li>To improve the content, usability, and navigability of the Edu Market Products;</li>
                            <li>To alert you to new Edu Market Products including new features, special events, and products and services;</li>
                        </ul>
                    </div>

                    <div class="wow fadeInUp">
                        <p class="font-bold">Will We Share any of Gathered Personal Data?</p>

                        <p>Your and children personal data is an integral part of our business. We neither rent nor sell it to anyone. We share your and children’s personal data only as described below. As explained below, we only share children’s personal data associated with your account directly with you to report on your child's performance using our Apps and Website. We never share that children’s personal data with any third party. A child's usage data (i.e. performance on tests, games, etc. available on the Apps and Website) is shared through aggregated, anonymous comparisons, but never in a way that could personally identify a child.</p>

                        <p>In addition, we do not provide children with chat rooms or community features that allow the sharing or posting of children's personal data in a public forum.</p>

                        <p>Again, please note that references to "your personal data" below only apply to personal information of individuals who are not children.</p>
                    </div>

                    <div class="wow fadeInUp">
                        <p><span class="font-bold">Sharing with affiliated businesses:</span> Over time, we may grow and reorganize. We may share your information, including personal data with affiliates such as a parent company, subsidiaries, joint venture partners or other companies that we control or that are under common control with us, in which case we will require those companies to agree to use your personal data in a way that is consistent with this privacy policy.</p>

                        <p>In the event that Edu Market is involved in a merger, reorganization, dissolution, sale of business or assets or similar event, information disclosed to or collected by us may be transferred to our successor, or to the purchaser of such assets, as applicable. You will be notified via email and a prominent notice on our Website of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal data.</p>
                    </div>

                    <div class="wow fadeInUp">
                        <p><span class="font-bold">External APIs:</span> We employ APIs of other companies and people to perform tasks on our behalf and may need to share your data with them, including personal data or children's personal data, to provide products or services to you. Examples include sending billing receipts and weekly progress reports and providing user services. Unless we tell you differently, these companies do not have any right to use personal data we share with them beyond what is necessary to execute tasks at hand. We will also require that these companies agree to protect the security of the information we share with them. You hereby consent to our sharing of personal data and children's personal data for the above purposes. For any privacy inquiries related to how these companies may handle your information on our behalf, please use the contact information at the bottom of this page, and we will respond to all such inquiries.</p>

                        <p><span class="font-bold">User profiles: </span>Minors are not allowed to enter or upload personal data sections in their personalised profiles. If the registration process is completed correctly, no children's personal data will ever be displayed to any third party through a user profile. Email addresses are used to make new submissions to user profiles and to communicate through user submissions. Users' email addresses will not be directly revealed to other users by Edu Market, except, when the user is "connected" to another user via a shared group membership, or an invitation, or if the user has chosen to include their email address in their user profile.</p>

                        <p><span class="font-bold">Communication about the services or in response to your questions: </span>As part of the services, you will receive from Edu Market email and other communication relating to your child's progress and use of the Website and Apps. You acknowledge and agree that by allowing your child to use Edu Market Products and by posting relevant service requests, Edu Market may send you email and other communication that it determines in its sole discretion relate to such activity and your questions or service requests.</p>

                        <p><span class="font-bold">Promotional Offers: </span>We may send offers to you on behalf of other businesses. However, when we do so, we do not give the other business your name and address. If you do not wish to receive these offers, please unsubscribe using the instructions provided in the email, the account management tools on the website, or by sending an email with your request to <a href="mailto:promo@gamezale.uz" class="link" target="_blank" rel="noopener noreferrer">promo@gamezale.uz.</a></p>

                        <p><span class="font-bold">Protection of Edu Market and Others: </span>We may release your personal data when we believe in good faith that release is necessary to comply with the law; enforce or apply our conditions of use and other agreements; or protect the rights, property, or safety of Edu Market, our employees, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.</p>

                        <p><span class="font-bold">With Your Consent: </span>Except as set forth above, you will be notified when your personal data may be shared with third parties, and you will be able to prevent the sharing of this information.</p>
                    </div>

                    <div class="wow fadeInUp">
                        <p class="font-bold">Use of Cookies:</p>

                        <p>Cookies are alphanumeric identifiers that we transfer to your computer's hard drive through your browser to enable our systems to recognize your browser and tell us how and when pages in our site are visited and by how many people. We use cookies to enhance visitors' experiences, to learn more about their use of the Edu Market Products and to improve quality. Edu Market cookies do not collect either your personal data or children's personal data, and we do not combine the general information collected through cookies with other personal data to tell us who you are or what your screen name or email address is. Most browsers have an option for turning off the cookie feature, which will prevent your browser from accepting new cookies, as well as (depending on the sophistication of your browser software) allowing you to decide on acceptance of each new cookie in a variety of ways. We strongly recommend that you leave the cookies activated, however, because cookies enable you to take advantage of some of our website's most attractive features. This privacy policy covers the use of cookies by us and does not cover the use of cookies by any advertisers or third parties. We do not currently display third party advertisements on Edu Market Products or use third party cookies to track children users for advertising purposes.</p>

                        <p class="font-bold">Email Communications:</p>
                        <p>We often receive a confirmation when you open an email from us if your computer supports this type of programme. Edu Market uses this confirmation to help us make emails more interesting and helpful. When you receive email from Edu Market, you may always opt out of receiving further emails by following the included instructions to unsubscribe.</p>

                        <p class="font-bold">Push Notifications:</p>
                        <p>We may also send parents (other guardians) practice reminders through our mobile apps. These notifications are intended for grownups only and can only be requested through the parent/grownup sections of our mobile apps. You can opt-out from these notifications or modify the frequency of these notifications at any time through the app or device settings.</p>
                    </div>


                    <div class="wow fadeInUp">
                        <p class="font-bold">How we protect information:</p>
                        <p>The security of your and your child personal data  is important to us. To prevent unauthorised access, disclosure, or improper use of gathered personal data, and to maintain data accuracy, we have established physical, technical, and administrative safeguards to protect the personal information we collect. In particular:</p>

                        <ol>
                            <li>We periodically review our information collection, storage and processing practices, including physical security
                            measures, to guard against unauthorised access to systems.</li>
                            <li>We perform application security testing; penetration testing; conduct risk assessments; and monitor compliance with
                            security policies.</li>
                            <li>When you enter any information anywhere on the App or Website, we encrypt the transmission of that information using
                            secure socket layer technology (SSL) by default.</li>
                            <li>Database where we store gathered personal data iis encrypted at rest, which converts all personal data stored in the
                            database to an unintelligible form.</li>
                            <li>We ensure passwords are stored and transferred securely using encryption and salted hashing.</li>
                            <li>Website and the Apps are hosted by third-party service providers at separate facilities, with whom we have a contract
                            providing for enhanced security measures.</li>
                            <li>We restrict access to gathered personal data to authorised employees, agents or independent contractors who need to
                            know that information in order to process it for us, and who are subject to strict confidentiality obligations and may
                            be disciplined or terminated if they fail to meet these obligations.</li>
                        </ol>
                    </div>

                    <div class="wow fadeInUp">
                        <p class="font-bold">Individual Rights:</p>
                        <p>Edu Market makes certain choices available to you when it comes to your personal data. Please review the below information outlining your choices and how to exercise them.</p>
                    </div>

                    <div class="wow fadeInUp">
                        <span class="font-bold">Review and update your data:</span>
                        <p>You have the right to access and update any personal data that we have collected. Some personal data can be updated using the account management tools via the App settings section. In case, you are unable to update personal data using App settings section, please, submit a request using the contact information at the end of this document. We may request more information to confirm your identity before modifying any personal data.</p>
                    </div>

                    <div class="wow fadeInUp">
                        <span class="font-bold">Delete your data:</span>
                        <p>You also have the right to get your personal data deleted. This is sometimes known as the ‘right to be forgotten’. To request that we delete all personal data about you, please submit a request using the contact information at the end of this section. We may request more information to confirm your identity before deleting any personal data.</p>
                    </div>

                    <div class="wow fadeInUp">
                        <span class="font-bold">Restrict Processing:</span>
                        <p>You have the right to restrict how we process your personal data in certain circumstances. This is an alternative to requesting the deletion of your data. Rather than requesting we delete all of your personal data, you may request that we limit our uses of your personal data to specific purposes. You may wish to request we restrict our processing if you contest the accuracy of your personal data and we are working to verify this information, or if you want us to retain your personal data in connection to a legal claim but cease processing it.</p>
                    </div>

                    <div class="wow fadeInUp">
                        <span class="font-bold">Data Portability:</span>
                        <p>You have the right to obtain copies of your information in a structured, commonly used format so that you can move your data between our service and the services of others. We may request more information to confirm your identity before providing any personal data.</p>
                    </div>

                    <div class="wow fadeInUp">
                        <span class="font-bold">Right to Object:</span>
                        <p>You have the right to object to the processing of your personal data for direct marketing purposes or when our processing of your data is based on legitimate interests. You may request that we stop processing your personal data for direct marketing purposes. This is an absolute right and we cannot refuse this request.</p>
                    </div>

                    <div class="wow fadeInUp">
                        <p class="font-bold">Data Deletion & Retention</p>
                        <p>We store your personal data for as long as it is necessary to provide products and services to you and others, including those described above. Personal data associated with your account will be kept until your account is deleted unless we no longer need the data to provide products and services.</p>
                        <p>Please note that we may have to retain some information after your account is closed, to comply with legal obligations, to protect the safety and security of our community or our products, or to prevent abuse of the terms of use of products and services. You can, of course, delete your account at any time.</p>
                    </div>

                    <div class="wow fadeInUp">
                        <span class="font-bold">Links:</span>
                        <p>The Edu Market Products may contain links to third-party websites. Edu Market is not responsible for the privacy practices or the content of such websites.</p>
                    </div>

                    <div class="wow fadeInUp">
                        <span class="font-bold">Contacts:</span>
                        <p>If you have any questions, concerns, or suggestions regarding this privacy policy, please contact us at <a href="mailto:support@gamezale.uz" class="link">support@gamezale.uz</a>.</p>
                        <p>Uzbekistan, Tashkent, D.str. Mirzo Ulugbek, str. Chust 10. Zip 100001 <br /> Effective: November, 2019Copyright &copy; 2019 Edu Market, All rights reserved.</p>
                    </div>
                </div>
            </div>
            <div class="text-center py-layout-xs wow fadeInUp">
                <Link to="/" class="btn btn--green">Go back</Link>
            </div>
        </section>
    )
}

export default PrivacyPage
