import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import translationRu from './locales/ru/translation.js'
import translationUz from './locales/uz/translation.js'
import { initReactI18next } from "react-i18next"

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
  // we init with resources
  resources: {
    ru: {
      translations: translationRu  
    },
    uz: {
      translations: translationUz  
    }
  },
  fallbackLng: "uz",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: '.', // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

//   react: {
//     wait: true
//   }
})

export default i18n