import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import Preloader from './Preloader'

const presentation = [3, 5, 6, 7, 2, 1, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19]
const k_garden = [2, 3, 4, 5, 6]
const school = [6, 1, 2, 3, 4, 5]
const mvp = [2,3]
const mdo = [2,3]

const GalleryPage = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
        setTimeout(() => {
            setIsLoading(false)
        }, 7000);
    })
    
    return (
        <section className="content pb-layout-sm">
            <div className="wrapper-header">
                <div className="container">
                    <Link to="/" className="d-inline-block title text-white text-shadow wow fadeIn">Edu Market</Link>
                </div>
            </div>
            <div className="container gallery">
                <h1 className="text-center title pt-layout-xs pb-layout-sm">{t('gallery.title')}</h1>

                {isLoading
                    ? <Preloader />
                    : <div className="gallery-grid">
                        <div className="gallery__item">
                            <div className="gallery__item-thumb" style={{backgroundImage: 'url(/images/gallery/presentation/bg.JPG)'}}></div>
                            <p>{t('gallery.item_1')}</p>
                            <SimpleReactLightbox>
                                <SRLWrapper>
                                    <a href="/images/gallery/presentation/4.JPG" data-attribute="SRL">
                                        <img src="/images/gallery/presentation/4.JPG" alt={t('gallery.item_1')} />
                                    </a>
                                    {presentation.map(m =>
                                        <a href={`/images/gallery/presentation/${m}.JPG`} style={{visibility: 'hidden'}}
                                            data-attribute="SRL" key={m}>
                                            <img src={`/images/gallery/presentation/${m}.JPG`} alt={t('gallery.item_1')} />
                                        </a>
                                    )}
                                    <a href="/images/gallery/presentation/21.JPG" data-attribute="SRL"
                                        style={{visibility: 'hidden'}}>
                                        <img src="/images/gallery/presentation/21.JPG" alt={t('gallery.item_1')} />
                                    </a>
                                    <a href="/images/gallery/presentation/22.JPG" data-attribute="SRL"
                                        style={{visibility: 'hidden'}}>
                                        <img src="/images/gallery/presentation/22.JPG" alt={t('gallery.item_1')} />
                                    </a>
                                </SRLWrapper>
                            </SimpleReactLightbox>
                        </div>
                        <div className="gallery__item">
                            <div className="gallery__item-thumb" style={{backgroundImage: 'url(/images/gallery/60-k-garden/bg.jpg)'}}></div>
                            <p>{t('gallery.item_2')}</p>
                            <SimpleReactLightbox>
                                <SRLWrapper>
                                    <a href="/images/gallery/60-k-garden/3.jpg" data-attribute="SRL">
                                        <img src="/images/gallery/60-k-garden/3.jpg" alt={t('gallery.item_2')} />
                                    </a>
                                    {k_garden.map(m =>
                                        <a href={`/images/gallery/60-k-garden/${m}.jpg`} style={{visibility: 'hidden'}}
                                            data-attribute="SRL" key={m}>
                                            <img src={`/images/gallery/60-k-garden/${m}.jpg`} alt={t('gallery.item_2')} />
                                        </a>
                                    )}
                                </SRLWrapper>
                            </SimpleReactLightbox>
                        </div>
                        <div className="gallery__item">
                            <div className="gallery__item-thumb" style={{backgroundImage: 'url(/images/gallery/school/bg.jpg)'}}></div>
                            <p>{t('gallery.item_3')}</p>
                            <SimpleReactLightbox>
                                <SRLWrapper>
                                    <a href="/images/gallery/school/7.jpg" data-attribute="SRL">
                                        <img src="/images/gallery/school/7.jpg" alt={t('gallery.item_3')} />
                                    </a>
                                    {school.map(m =>
                                        <a href={`/images/gallery/school/${m}.jpg`} style={{visibility: 'hidden'}}
                                            data-attribute="SRL" key={m}>
                                            <img src={`/images/gallery/school/${m}.jpg`} alt={t('gallery.item_3')} />
                                        </a>
                                    )}
                                </SRLWrapper>
                            </SimpleReactLightbox>
                        </div>
                        <div className="gallery__item">
                            <div className="gallery__item-thumb" style={{backgroundImage: 'url(/images/gallery/mvp/bg.JPG)'}}></div>
                            <p>{t('gallery.item_4')}</p>
                            <SimpleReactLightbox>
                                <SRLWrapper>
                                    <a href="/images/gallery/mvp/1.JPG" data-attribute="SRL">
                                        <img src="/images/gallery/mvp/1.JPG" alt={t('gallery.item_4')} />
                                    </a>
                                    <a href="/images/gallery/mvp/2.JPG" data-attribute="SRL"
                                        style={{visibility: 'hidden'}}>
                                        <img src="/images/gallery/mvp/2.JPG" alt={t('gallery.item_4')} />
                                    </a>
                                    <a href="/images/gallery/mvp/3.JPG" data-attribute="SRL"
                                        style={{visibility: 'hidden'}}>
                                        <img src="/images/gallery/mvp/3.JPG" alt={t('gallery.item_4')} />
                                    </a>
                                    {mvp.map(m =>
                                        <a href={`/images/gallery/mvp/${m}.jpg`} style={{visibility: 'hidden'}}
                                            data-attribute="SRL" key={m}>
                                            <img src={`/images/gallery/mvp/${m}.jpg`} alt={t('gallery.item_4')} />
                                        </a>
                                    )}
                                </SRLWrapper>
                            </SimpleReactLightbox>
                        </div>
                        <div className="gallery__item">
                            <div className="gallery__item-thumb" style={{backgroundImage: 'url(/images/gallery/mdo/bg.jpg)'}}></div>
                            <p>{t('gallery.item_5')}</p>
                            <SimpleReactLightbox>
                                <SRLWrapper>
                                    <a href="/images/gallery/mdo/1.jpg" data-attribute="SRL">
                                        <img src="/images/gallery/mdo/1.jpg" alt={t('gallery.item_5')} />
                                    </a>
                                    {mdo.map(m =>
                                        <a href={`/images/gallery/mdo/${m}.jpg`} style={{visibility: 'hidden'}}
                                            data-attribute="SRL" key={m}>
                                            <img src={`/images/gallery/mdo/${m}.jpg`} alt={t('gallery.item_5')} />
                                        </a>
                                    )}
                                </SRLWrapper>
                            </SimpleReactLightbox>
                        </div>
                    </div>
                }
            </div>
        </section>
    )
    
}

export default GalleryPage
