import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import imgItsm from 'assets/images/partners/itsm.png'
import imgMno from 'assets/images/partners/mno.png'

const Partners = () => {
	const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo({
            top: 0
        })
	})
	
	return (
        <section className="content pb-layout-sm">
            <div className="wrapper-header">
                <div className="container">
                    <Link to="/" className="d-inline-block title text-white text-shadow wow fadeIn">Edu Market</Link>
                </div>
            </div>
            <div className="content-wrap">
                <div className="redactor-content py-layout-xs">
					<h3 className="title text-center mb-layout-xs wow fadeInUp">{ t('partners.title') }</h3>
					<div dangerouslySetInnerHTML={{__html: t('partners.content')}}></div>
                    <img src={imgMno} className="partners-image mno itsm wow fadeInUp" data-wow-delay="0.2s" alt="MNO"/>
					<div dangerouslySetInnerHTML={{__html: t('partners.ITSM')}}></div>
                    <img src={imgItsm} className="partners-image itsm wow fadeInUp" alt="ITSM"/>
					<div dangerouslySetInnerHTML={{__html: t('partners.MNO')}}></div>
				</div>
			</div>
		</section>
	)
}

export default Partners
