export function secondsToHms(d, lang = 'ru') {
    
    d = Number(d);
    var h = Math.floor(d / 3600),
        m = Math.floor(d % 3600 / 60),
        s = Math.floor(d % 3600 % 60),
        hDisplay,
        mDisplay,
        sDisplay

    if (lang === 'ru') {
        hDisplay = h > 0 ? h + (h === 1 ? ' ч ' : ' ч ') : '';
        mDisplay = m > 0 ? m + (m === 1 ? ' м ' : ' м ') : '';
        sDisplay = s > 0 ? s + (s === 1 ? ' с' : ' с') : '';
    }
    else {
        hDisplay = h > 0 ? h + (h === 1 ? ' s ' : ' s ') : '';
        mDisplay = m > 0 ? m + (m === 1 ? ' d ' : ' d ') : '';
        sDisplay = s > 0 ? s + (s === 1 ? ' s' : ' s') : '';
    }
    
    return hDisplay + mDisplay + sDisplay;
}
// var hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
// var mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
// var sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';