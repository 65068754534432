// import Preloader from 'components/Preloader'
import React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Parallax from 'parallax-js'
import { Link } from 'react-router-dom'
import logo from 'assets/images/celebration/logo.png'
import baloonsLeft from 'assets/images/celebration/baloons-left.png'
import baloonsRight from 'assets/images/celebration/baloons-right.png'

const Celebration = () => {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({
        top: 0
    })
    initParallax()
  }, [])

  function initParallax() {
    let parallax = document.querySelectorAll('.js-parallax')
    let parallax2 = document.querySelectorAll('.js-parallax-2')

    parallax.forEach(element => {
      new Parallax(element)
    })
    parallax2.forEach(element => new Parallax(element, {
      invertX: false,
      invertY: false
    }))
  }

  return (
    <section className="celebration">
      <div className="celebration-overlay js-parallax">
        <div className="celebration-overlay__wrap layer" data-depth="0.05"></div>
      </div>
      <div className="celebration__baloon celebration__baloon--left js-parallax">
        <img src={baloonsLeft}
        className="layer"
        alt="baloons"
        data-depth="0.3"/>
      </div>
      <div className="celebration__baloon celebration__baloon--right js-parallax-2">
        <img src={baloonsRight}
          className="layer"
          alt="baloons"
          data-depth="0.4"/>
      </div>
      <Link to="/" className="celebration__logo wow fadeIn"><img src={logo} alt="logo"/></Link>
      <h1 className="celebration__title wow fadeInUp"><span>330 000</span> { t('celebration.title_text') }</h1>
      <div className="celebration-content wow fadeInUp" data-wow-delay="0.1s">
        <div className="celebration-content__inner redactor-content text-center lh-lg" data-wow-delay="0.1s">
          <div dangerouslySetInnerHTML={{__html: t('celebration.text')}}></div>
        </div>
      </div>
    </section>
  )
}

export default Celebration