export default {
  seedstars_contest: 'ООО "Game Zale" - обладатель почетного 2-го места в международном конкурсе стартапов "Seedstars Tashkent 2020"',
  main:
    'Превратите гаджет для детей не только в развлекательный, но и обучающий инструмент',
  more: 'Подробнее',
  learnMore: 'Узнать больше',
  downloadApp: 'Скачать приложение',
  downloadFromSite: 'Скачать с сайта',
  cabinet: '<span>Личный кабинет</span><span class="hidden">Кабинет</span>',
  loading: 'Загрузка',
  leaderboard: 'Лидеры',
  leaders_table: 'Таблица лидеров',
  days: 'д',
  hours: 'ч',
  minutes: 'м',
  seconds: 'с',
  benefit: {
    title: 'Польза Edu Market',
    info_1:
      'Тренировки, разработанные нашими педагогами, расширяют объем приобретенных навыков за счет усложняющихся заданий',
    info_2:
      'Ребенок будет получать награды за правильные ответы и усердие, а также вместе с каждой пройденной тренировкой получать предметы для создания своей виртуальной школы'
  },
  call: {
    title: 'Система онлайн обучения и доступа к предметам',
    info:
      'Оценка знаний, мониторинг и анализ массовых статистических данных в школьных учреждениях'
  },
  eco: {
    main_title: 'Возможности Edu Market',
    title_1: 'Виртуальная школа',
    info_1:
      'Упорядоченная коллекция различных книг, журналов, учебных пособий, снабжённых средствами навигации и поиска по категориям',
    title_2: 'Система Online обучения и доступа к предметам',
    info_2:
      'Огромный каталог обучающих материалов по различным предметам 24/7 по всему миру',
    title_3: 'Виртуальная библиотека',
    info_3:
      'Образовательное учреждение, в котором педагогический процесс и обучение студентов/школьников осуществляются через гаджеты',
    title_4: 'Онлайн статистика успеваемости ребенка',
    info_4:
      'Образовательное учреждение, в котором педагогический процесс и обучение студентов/школьников осуществляются через гаджеты',
    title_5: 'Виртуальный город, виртуальный дом',
    info_5:
      'Созданный техническими средствами мир, передаваемый человеку через его ощущения: зрение, слух, осязание и другие',
    title_6: 'Онлайн поинты (очки)',
    info_6:
      'Онлайн поинты (очки) – Поинты, которые можно обменять на Coin (внутреннюю валюту) и получить различные бонусы за успешное прохождение заданий'
  },
  pupils: {
    title: 'Что получат ученики',
    info_1: 'Доступ к обучающим материалам по различным предметам',
    info_2: 'Различные бонусы за успешное прохождение заданий',
    info_3: 'Поинты, которые можно обменять на Coin (внутреннюю валюту)',
    info_4:
      'Улучшенная социализация (общение с учениками других классов и школ)',
    info_5: 'Более полезное времяпровождение с гаджетами',
    info_6: 'Мотивацию для учебы'
  },
  goal: {
    title: 'Цели проекта',
    info_1:
      'Сделать процесс обучения и усвоения знаний более удобным и интересным',
    info_2:
      'Прозрачная система мониторинга успеваемости ребенка для родителей и педагогов',
    info_3:
      'Выстроить правильную систему получения знаний для каждого возраста',
    info_4:
      'Сделать гаджет для детей не только развлекательным, но и полноценным обучающим инструментом'
  },
  steam: {
    title: 'STEAM Образование',
    info:
      'Поколение STEAM это самое востребованное поколение в современном мире. STEAM образование это не только метод обучения, но и способ мышления, усваивание материала, путем включения практических занятий в процесс обучения. Делая акцент на практических способностях, школьники развивают свою силу воли, творческий потенциал, гибкость и учатся сотрудничеству с другими.'
  },
  parents: {
    title: 'Что получат родители',
    info_1: 'Более тесное взаимодействие с учениками',
    info_2: 'Понимание интересов и увлечений ребенка',
    info_3: 'Прямая коммуникация с учителями',
    info_4: 'Статистика успеваемости в реальном времени'
  },
  government: {
    title: 'Что получит государство',
    info_1: 'Подготовленные кадры в различных областях',
    info_2: 'Более глубокое понимание интересов молодежи',
    info_3: 'Платформа для задания вектора направления развития молодежи',
    info_4:
      'Раннее выявление слабых мест в кадровом ресурсе для последующей коррекции'
  },
  footer: {
    contact_us: `По вопросам сотрудничества можете связаться с нами по электронной почте <a href="mailto:developer@gamezale.com" class="text-underline">
      info@gamezale.uz
    </a>`,
    rights: 'Все права защищены',
    policy: 'Политика конфиденциальности',
    terms: 'Пользовательское соглашение',
    devs: 'Разработано в'
  },
  results: {
    player_id: 'ID игрока',
    points: 'Очки',
    spent_time: 'Время в игре'
  },
  partners: {
    title: 'Наши партнеры',
    content: `
            <div class="wow fadeInUp pb-layout-xs" data-wow-delay="0.1s">
                <p>Успешный запуск интерактивно-виртуальной онлайн платформы Edu Market стала возможной благодаря активному участию и профессиональной поддержки таких организаций как Министерство народного образования Республики Узбекистан, ГУП «Центр инновации, технологии и стратегии» при Министерстве народного образования, АК «Алокабанк», A’lo finance и другие.</p>
                <p>Мы благодарны за их присутствие в нашей деятельности, всестороннюю поддержку и надеемся на дальнейшее плодотворное сотрудничество в будущих проектах.</p>
                <p>Выражаем особую благодарность Министерству народного образования и ГУП «Центр инновации, технологии и стратегии» за оказанное содействие на этапах тестирования, реализации, проведения анализа и доработки контента данной платформы.</p>
            </div>
        `,
    ITSM: `
            <div class="wow fadeInUp pb-layout-xs" data-wow-delay="0.2s">
                <h1 class="font-bold pb-md">Министерство народного образования</h1>
                
                <p>Министерство народного образования является уполномоченным органом государственного управления Республики Узбекистан, который вырабатывает и реализует единую государственную политику в сфере общего среднего и внешкольного образования.</p>
                <p>Одним из приоритетных задач Министерства является выработка и реализация единой государственной политики в сфере общего среднего и внешкольного образования, дальнейшее повышение уровня духовно-нравственного, интеллектуального и физического развития учащихся.</p>
                <p>Для качественной реализации поставленных задач перед Министерством разработана стратегия развития сферы общего среднего и внешкольного образования, координируются комплексные меры по оптимизации и эффективному применению информационных технологий для внедрения в учебно-воспитательный процесс.</p>
            </div>
        `,
    MNO: `
            <div class="wow fadeInUp pb-layout-xs">
                <h1 class="font-bold pb-md">Центр инновации, технологии и стратегии при Министерстве народного образования</h1>
                
                <p>Миссией Центра является инновационное развитие системы народного образования, внедрение современных инновационных технологий с использованием цифровых технологий, веб-платформ, программных продуктов и информационных систем, проведение стратегического анализа и планирования дальнейшего развития системы.</p>
                <p>На сегодняшний день, Центр разрабатывает и реализует ряд проектов такие как, Президентские школы Республики Узбекистан, интерактивно-виртуальная образовательная программа «Edu Market», веб-портал подачи идей по развитию сферы народного образования «idea.itsm.uz», веб-портал «kitob.uz», нацеленный на повышение культуры чтения среди населения и создание возможности слушать и читать книги, серия научно-популярных анимационных видео-дайджестов «Edu Kids», а также ряд проектов по реализации и внедрению новых инициатив в систему народного образования.</p>
            </div>
        `
  },
  gallery: {
    title: 'Галерея',
    item_1: 'Официальная презентация проекта',
    item_2: 'Тестирование продукта в детском саду 60',
    item_3: 'DEMO тесты в школах 64 и 94',
    item_4: 'Начало разработки и реализация MVP',
    item_5: 'Показ в Министерстве Дошкольного Образования'
  },
  contest: {
    title: 'Конкурс',
    info_1:
      'Edu Market совместно с Министерством Образования Республики Узбекистан организовывают онлайн-олимпиаду для детей до 12 лет.',
    text_1: `Дорогие друзья! Олимпиада будет длится 8 недель: с 26.10.2020г. до 21.12.2020г. 
        Еженедельно будут награждаться 10 победителей по соответственно занятым местам в таблице лидеров. Принять участие в онлайн-олимпиаде может любой ребёнок, находящийся на территории Республики Узбекистан в возрасте до 12 лет.`,
    text_2: `<span class="text-underline" style="font-size: 18px">Примечания:</span><br/>
                - Все игры Edu Market (за исключением раздела «Арифметика») возможно играть повторно до 3 раз включительно – далее очки учитываться не будут;<br/>
                - В разделе «Арифметика» допускаются только 3 попытки по 10 баллов максимально за каждую попытку;<br/>
                - Ребёнок может стать победителем только единожды;<br/>
                - Победители и призёры прошлого соревнований в данной олимпиаде не участвуют;<br/>
                - Рекомендуем родителям НЕ решать задачи за своих детей!`,
    info_2: 'Все участники еженедельно будут соревноваться за призы:',
    prize: {
      info_1: '1 место',
      text_1: 'Планшет, футболка, кепка, купон, сертификат',
      info_2: '2 и 3 место',
      text_2: 'Футболка, кепка, купон, сертификат, ручка, тетрадь',
      info_3: 'с 4 по 10 место',
      text_3: 'Футболка, кепка, ручка, тетрадь'
    },
    info_3:
      'Что нужно сделать для того, чтобы принять участие в онлайн олимпиаде?',
    list_1: 'Установить себе на смартфон или планшет приложение Edu Market',
    list_2:
      'Зарегистрироваться в приложении Edu Market (обязательно указав свою фамилию, имя и контактный номер телефона, что бы мы связались с вами в случае выигрыша)',
    list_3: 'Играйте в любые игры внутри приложения, чтобы зарабатывать очки.',
    list_4:
      'Проверить набранные вами очки вы можете в приложении либо на сайте нажав на кнопку “Лидеры”',
    left: 'до старта конкурса осталось',
    started: 'Конкурс уже начался!',
    will_finish: 'до конца конкурса осталось',
    first_stage: 'Прошел первый этап конкурса!<br />Наши победители:',
    second_stage: 'Прошел второй этап конкурса!<br />Наши победители:',
    sud_info:
      'Уважаемые участники онлайн-олимпиады, к сожалению мы вынуждены приостановить нашу олимпиаду на период с 13.07.2020 до 19.07.2020 включительно. Очки заработанные в данный период учитываться не будут.',
    sud_info_1:
      'Уважаемые участники онлайн-олимпиады, к сожалению мы вынуждены приостановить нашу олимпиаду на период с 13.07.2020 до 19.07.2020 включительно. Очки заработанные в данный период учитываться не будут. Причиной приостановки олимпиады стали люди которые пытались нечестным путём занимать лидирующие места. Мы хотим провести честную олимпиаду, в которой дети заинтересованные в образовании и обучении смогут выиграть призы за свои усилия.',
    sud_info_2:
      '2й этап онлайн-олимпиады начнётся с 20.07.2020 и продолжится в стабильном режиме, поэтому у вас есть время подготовиться к предстоящему состязанию.',
    sud_info_3:
      'Вручение всех призов состоится в августе, в связи со сложившейся ситуацией с коронавирусной инфекцией.',
    soon_stats:
      'Олимпиада уже началась, статистика будет доступно в скором времени',
    stage_completed: 'Прошел очередной этап конкурса!<br />Наши победители:',
    old_stage_completed:
      'Результаты предыдущего конкурса<br />Наши победители:',
    week_1: 'Неделя 1',
    week_2: 'Неделя 2',
    week_3: 'Неделя 3',
    week_4: 'Неделя 4',
    week_5: 'Неделя 5',
    week_6: 'Неделя 6',
    week_7: 'Неделя 7',
    week_8: 'Неделя 8',
    end: 'Конкурс закончен!'
  },
  news: {
    title: 'Новости'
  },
  celebration: {
    title: 'Нас больше 330 тыс. пользователей!',
    title_short: 'Нас 330 000!',
    title_text: 'пользователей!',
    text: `
            <p style="font-size: 18px; line-height: 28px">
            Дорогие друзья, от имени нашей команды мы благодарим вас за участие в нашем проекте!<br />
            Да, именно вы помогаете нам с каждым разом улучшать платформу Edu&nbsp;Market, а самое главное давать детям новые знания!<br /></p>
            <p style="font-size: 20px" class="text-danger">Спасибо что вы с нами!</p>
        `
  },
  history: {
    title: 'История',
    sep_2018: `
            <div>
                <h3>
                    <p>Сентябрь</p>
                    <p>2018</p>
                </h3>
            </div>
            <div>
                <h3>Начало разработки</h3>
                <p>Наша команда определилась с авторским видением, трактовкой и базовой идеей. Были сформулированы фундаментальные принципы, стратегия и&nbspдействия по разработке платформы.</p>
                <img src="/images/history/sep-2018-1.jpg" alt="history"/>
                <img src="/images/history/sep-2018-2.jpg" alt="history"/>
            </div>
        `,
    feb_2019: `
            <div>
                <h3>
                    <p>Февраль</p>
                    <p>2019</p>
                </h3>
            </div>
            <div>
                <h3>Выпуск MVP</h3>
                <p>Опираясь на концептуальные видения, мы реализовали основной функционал платформы, которая своими функциями удовлетворяла основные запросы аудитории.</p>
                <img src="/images/history/feb-2019.JPG" alt="history"/>
            </div>
        `,
    is_2019_1: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Разработано 1000+ развивающих игр</h3>
                <p>Мы - как творчески настроенный персонал заряженный командным духом и&nbspэнтузиазмом в кратчайшие сроки реализовали более 1000 мини-игр по&nbspразличным тематикам и возрастам для наших маленьких пользователей.</p>
            </div>
        `,
    is_2019_2: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Разработана системы статистики</h3>
                <p>Статистика – важная составляющая успеха любого учебного процесса. В&nbspплатформе, статистика также необходимая вещь для мониторинга показателей, анализа достоинств и недостатков. Так, нами разработана гибкая система сбора и анализа статистики данных. Пользователи также могут следить за своими успехами и неудачами, тем самым совершенствуя свои знания.</p>
                <img src="/images/history/is-2019-2.png" alt="history"/>
            </div>
        `,
    is_2019_3: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Разработан персональный кабинет для родителя</h3>
                <p>Все родители наших пользователей  могут с легкостью наблюдать за&nbspуспехами своих детей через персональный кабинет. Это позволит без&nbspлишних вопросов оказать необходимую помощь, либо просто - не мешать.</p>
                <img src="/images/history/is-2019-3.png" alt="history"/>
            </div>
        `,
    mar_2019_1: `
            <div>
                <h3>
                    <p>Март</p>
                    <p>2019</p>
                </h3>
            </div>
            <div>
                <h3>MVP был представлен руководству Министерства народного образования Республики Узбекистан</h3>
                <p>В марте 2019 года, мы презентовали платформу министру народного образования Республики Узбекистан, "Центру инновации, технологии и&nbspстратегии", а также руководству подразделений центрального аппарата министрества. Наш продукт был высоко оценен специалистами, что придало начало нашему плодотворному сотрудничеству.</p>
                <img src="/images/history/mar-2019-1-1.png" alt="history"/>
                <img src="/images/history/mar-2019-1-2.png" alt="history"/>
            </div>
        `,
    mar_2019_2: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>MVP был представлен руководству Республиканского центра образования при Министерстве народного образования Республики Узбекистан</h3>
                <p>Наш продукт заручившись положительными рекомендациями руководства Министерства, продолжил свой путь к профильной организации, которая непосредственно занимается формированием учебного процесса. Руководство Республиканского центра образования оценило нашу работу и&nbspпредоставило свои ценные комментарии.</p>
                <img src="/images/history/mar-2019-2-1.png" alt="history"/>
                <img src="/images/history/mar-2019-2-2.png" alt="history"/>
            </div>
        `,
    mar_2019_3: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Подписание Меморандума о взаимном сотрудничестве с Министерством народного образования Республики Узбекистан</h3>
                <p>Результатом нашей работой стало подписание Меморандума о взаимном сотрудничестве с Министерством народного образования Республики Узбекистан с постановкой конкретных целей и задач в области образовательного процесса посредством нашей платформы.</p>
            </div>
        `,
    oct_2019: `
            <div>
                <h3>
                    <p>Октябрь</p>
                    <p>2019</p>
                </h3>
            </div>
            <div>
                <h3>DEMO тесты в школах 64 и 94 г.Ташкента (с участием более 2200 детей)</h3>
                <p>При поддержке Министерства Народного Образования наш продукт был протестирован среди учащихся школ  64 и 94 Ташкента (более 2200 детей) с&nbspопределением победителей в нескольких проведенных конкурсах с нашим продуктом.</p>
                <img src="/images/history/oct-2019-1.jpg" alt="history"/>
                <img src="/images/history/oct-2019-2.png" alt="history"/>
                <img src="/images/history/oct-2019-3.png" alt="history"/>
            </div>
        `,
    dec_2019_1: `
            <div>
                <h3>
                    <p>Декабрь</p>
                    <p>2019</p>
                </h3>
            </div>
            <div>
                <h3>Показ DEMO версии Edu Market в&nbspМинистерстве Дошкольного Образования</h3>
                <p>После успешного тестирования наших программ в ташкентских школах, с&nbspнашими продуктами были ознакомлены также работники Министерства Дошкольного Образования.</p>
                <img src="/images/history/dec-2019-1.JPG" alt="history"/>
            </div>
        `,
    dec_2019_2: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Подписание Меморандума о&nbspсотрудничестве с МДО</h3>
                <p>Результатом более глубокого изучения нашего продукта специалистами Министерства Дошкольного Образования явилось подписание Меморандума о нашем сотрудничестве</p>
            </div>
        `,
    feb_2020_0: `
            <div>
                <h3>
                    <p>Февраль</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Стратегический договор о&nbspсотрудничестве</h3>
                <p>5 февраля 2020 года между нашей компанией и Центром инновации, технологии и стратегии при Министерстве народного образования был заключён стратегический договор о сотрудничестве. Наше партнёрство позволит дополнительно привлекать высоких профессионалов в отрасли образования а также первоклассных экспертов методики интерактивного обучения детей.</p>
                <img src="/images/history/feb-2020-0-1.JPG" alt="history"/>
                <img src="/images/history/feb-2020-0-2.JPG" alt="history"/>
            </div>
        `,
    feb_2020_1: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Интеграция с платежной системой PayMe</h3>
                <p>Для успешного продвижения наших продуктов на рынке и удобства приобретения пользователями была произведена интеграция с популярной платежной системой нашей страны PayMe, что позволило нам еще более упростить  пользование нашими продуктами.</p>
                <img src="/images/history/feb-2020-1.png" alt="history"/>
            </div>
        `,
    feb_2020_2: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Тестирование продукта в детском саду 60 г.Ташкента (более 200 детей)</h3>
                <p>Для исследования наших проектов среди самых маленьких пользователей был предоставлен детский сад №60 г.Ташкента. Тестирование продукта прошло с большим успехом, участие принили более 200 детей дошкольного возраста.</p>
                <img src="/images/history/feb-2020-2-1.jpg" alt="history"/>
                <img src="/images/history/feb-2020-2-2.jpg" alt="history"/>
            </div>
        `,
    feb_2020_3: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Презентация продукта Директору по&nbspмаркетингу компании «Rovio» (Финляндия), а также директору по&nbspмаркетингу популярной игры «Angry Birds» Питеру Вестербаку</h3>
                <p>При организации IT Park состоялась встреча руководства нашей компании с&nbspдиректором по&nbspмаркетингу популярной игры «Angry Birds», господином П.Вестербаком. На встрече была презентована платформа Edu Market, а&nbspтакже получены ценные советы от профессионала в области разрабботки мобильных игр.</p>
                <img src="/images/history/feb-2020-3.jpg" alt="history"/>
            </div>
        `,
    feb_2020_4: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Презентация платформы и грандиозное награждение победителей конкурса</h3>
                <p>Масштабная презентация нашей платформы прошла в современном конферен-зале Университета Инха г.Ташкента, на мероприятии выступал министр народного образования Республики Узбекистан, руководство профильных организаций министрества и нашей организации. Также, состоялось торжественное вручение призов детям школ, в которых проводились DEMO тесты. Данное мероприятие широко освещалось во всех новостных сайтах и на национальном телевидении Узбекистана.</p>
                <img src="/images/history/feb-2020-4-1.jpg" alt="history"/>
                <img src="/images/history/feb-2020-4-2.JPG" alt="history"/>
                <img src="/images/history/feb-2020-4-3.jpg" alt="history"/>
            </div>
        `,
    mar_2020: `
            <div>
                <h3>
                    <p>Март</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Публикация в Google Play</h3>
                <p>Всем известна международная он-лайн площадка интернета Google play, предоставляющая интернет-пользователям огромное количество продуктов.В марте 2020 года наш продукт был успешно опубликован на&nbspэтой мировой игровой площадке.</p>
                <img src="/images/history/mar-2020-1.png" alt="history"/>
                <img src="/images/history/mar-2020-2.png" alt="history"/>
            </div>
        `,
    apr_2020_1: `
            <div>
                <h3>
                    <p>Апрель</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Публикация в AppStore</h3>
                <p>В апреле 2020 прошла успешная публикация нашего продукта еще на&nbspдругой мировой торговой площадке AppStore – магазин приложений, позволяющий их купить или скачать бесплатно.</p>
            </div>
        `,
    apr_2020_2: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Edu Market в списке приложений с&nbspнетарифицируемым интернет-трафиком!</h3>
                <p>В связи с высокой популярностью нашего приложения и растущим интересом дистанционного обучения, мобильные операторы и интернет-провайдеры включили нашу платформу в перечень нетарифицируемого контента.</p>
            </div>
        `,
    apr_2020_3: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Edu Market в социальных видео роликах!</h3>
                <p>Благодаря поддержке руководства Министерства народного образования Республики Узбекистан наш продукт неоднократно упоминался в перечне рекомендуемых инструментов дистанционного обучения. В целях качественного информирования, социальные видео ролики, главные роли в&nbspкоторых исполняли знаменитые артисты и титулованные спортсмены, многократно трансировались на каналах онлайн обучения на телевидении.</p>
            </div>
        `,
    apr_2020_4: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Edu Market как лучшее решение для учеников в период карантина</h3>
                <p>Учитывая что всю IV учебную четверть ученики проведут дом, наша платформа внесла достойный вклад в предоставлении эффективного обучения. Наш метод ещё раз доказал: гаджет это не только деструктивные игры, но и реальное обучение, весело и с пользой.</p>
            </div>
        `,
    may_2020_1: `
            <div>
                <h3>
                    <p>Май</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Мы преодолели порог в 160 000 скачиваний нашего приложения!</h3>
                <p>Уже в мае нашими пользователями было успешно произведено 160 000 скачиваний нашего приложений с международных игровых площадок</p>
            </div>
        `,
    may_2020_2: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>60 000 ежедневных пользователей приложения!</h3>
                <p>И как результат нашей предыдущей деятельности мы достигли  – 60 000 пользователей продуктов компании ЕЖЕДНЕВНО</p>
            </div>
        `,
    may_2020_3: `
            <div>
                <h3>
                    <p></p>
                </h3>
            </div>
            <div>
                <h3>Интеграция с платежной системой Click</h3>
                <p>Для более успешного продвижения нашего продукта была необходимость интеграции с ведущими платежными системами страны, не только PayMe, но&nbspи Click, что успешно было сделано в мае 2020 года</p>
                <img src="/images/history/feb-2020-1.png" alt="history"/>
            </div>
        `,
    jul_2020: `
            <div>
                <h3>
                    <p>Июль</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Подключение первого партнера SNG детского контента в маркет плэйс Edu Market</h3>
                <p>Главной задачей нового сервиса - SNG - является предоставление всем своим клиентам широкого доступа с любого устройства с выходом в интернет в&nbspкачестве Full HD(4K) и формате 3D. Наше партнерство с данным видеоконтентом является стратегически важным  для дальнейшего развития и продвижения своего прдукта.</p>
            </div>
        `,
    q3_2020: `
            <div>
                <h3>
                    <p>3й Квартал</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Подключение мобильных операторов Ucell и Beeline</h3>
                <p>Партнерство с ведущими мобильными операторами нашей республики является стратегически важным аспектом развития нашей компании и&nbspогромным шагом в определении основных приоритетов в работе с&nbspпользователями.</p>
            </div>
        `,
    jul_aug_2020: `
            <div>
                <h3>
                    <p>Июль&nbsp-&nbspАвгуст</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Более 20000 школьников приняли участие в онлайн-олимпиаде Edu Market</h3>
                <p>
                    Как сообщалось ранее, среди детей школьного возраста была проведена онлайн-олимпиада интерактивной виртуальной образовательной программы Edu Market, организованная в сотрудничестве с Министерством народного образования, Центром инновации, технологий и стратегии, а также GameZale.
                    <br /><br />
                    <img src="/images/history/july-aug-2020.png" alt="history"/>
                    В олимпиаде, проводимой среди пользователей платформ Android и iOS, приняли активное участие более 20 000 школьников в возрасте до 12 лет.
                    <br /><br />
                    Сарвинос Ривожхонова выиграла за 1 неделю, Халима Вахидова, Зухра Акбаровна и Алибек Бахшуллаев заняли первое место за 2, 3 и 4 недели соответственно.
                    <br /><br />
                    Победители, занявшие 1 место, получат главный приз - Планшет, а остальные победители получат подарочные сувениры.
                    <br /><br />
                    Церемония награждения состоится после улучшения эпидемиологической ситуации в стране.
                </p>
            </div>
        `,
    sep_2020: `
            <div>
                <h3>
                    <p>Сентябрь</p>
                    <p>2020</p>
                </h3>
            </div>
            <div>
                <h3>Награждение победителей <br /> онлайн-олимпиады</h3>
                <img src="/images/history/sep-2020-1.jpg" alt="history"/>
                <img src="/images/history/sep-2020-2.jpg" alt="history"/>
                <img src="/images/history/sep-2020-3.jpg" alt="history"/>
                <img src="/images/history/sep-2020-4.jpg" alt="history"/>
            </div>
        `
  }
}
