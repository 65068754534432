import React, { Component } from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import SimpleReactLightbox from "simple-react-lightbox"
import WOW from 'wow.js'
import i18n from "i18next"

import Header from './components/TheHeader'
import HomePage from './components/Home'
import PrivacyPage from './components/Privacy'
import HistoryPage from './components/History'
import GalleryPage from './components/Gallery'
import TermsPage from './components/Terms'
import LeaderboardPage from './components/Leaderboard'
import ContestPage from './components/Contest'
import PartnersPage from './components/Partners'
import CelebrationPage from 'components/Celebration'
import getCookie from 'utilities/getCookie'
import setCookie from 'utilities/setCookie'

import imgPosterRu from 'assets/images/poster-ru.jpeg'
import imgPosterUz from 'assets/images/poster-uz.jpeg'

export class App extends Component {
	state = { 
		lang: 'ru',
		isPosterVisible: false,
		imgPoster: i18n.language === 'ru' ? imgPosterRu : imgPosterUz
	}

	componentDidMount() {
		const wow = new WOW()
		wow.init()

		if (getCookie('isPosterDisplayed') !== 'yes' && window.innerWidth > 992)
			setTimeout(() => this.togglePoster(), 1500)
	}

	togglePoster = () => {
		const { isPosterVisible } = this.state

		if (!isPosterVisible) {
				document.querySelector('.js-poster').classList.add('in')
				document.querySelector('body').classList.add('o-hidden')

				setCookie('isPosterDisplayed', 'yes', 1)

				this.setState({isPosterVisible: true})
		}
		else {
				document.querySelector('.js-poster').classList.remove('in')
				document.querySelector('body').classList.remove('o-hidden')
		}
	}

	constructor(props) {
		super(props)

		this.props.history.listen(() => {
			window.scrollTo({
				top: 0
			})
		})
	}

	handleLangChange = val => {
		if (this.props.location.pathname === '/contest')
			return this.setState({lang: val}, () => window.location.reload())
		this.setState({lang: val})
	}
	
	render() {
		const { lang, imgPoster } = this.state

		return (
			<div className="wrapper">
				<Header onLangChange={this.handleLangChange} lang={lang} />
				<div className="poster js-poster">
					<div className="poster-content">
						<button className="poster__dismisser"
							onClick={this.togglePoster}></button>
						<img src={imgPoster} alt="poster"/>
					</div>
				</div>
				<SimpleReactLightbox>
					<Switch>
						<Route path="/leaderboard" component={LeaderboardPage} />
						<Route path="/contest" component={ContestPage} />
						<Route path="/privacy" component={PrivacyPage} />
						<Route path="/history" component={HistoryPage} />
						<Route path="/gallery" component={GalleryPage} />
						<Route path="/terms" component={TermsPage} />
						<Route path="/partners" component={PartnersPage} />
						<Route path="/celebration" component={CelebrationPage} />
						<Route path="/" exact component={HomePage} />
						<Redirect to="/" />
					</Switch>
				</SimpleReactLightbox>
			</div>
		)
	}
}

export default withRouter(App)

